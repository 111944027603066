import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { connect } from 'react-redux';

export function getBreadcrumbString(hier) {
  if (hier && hier.length > 0) {
    var out = [];
    hier.forEach(function (account, index) {
      out.push(account.Name);
    });
    return out.join(" / ");
  } else {
    return "";
  }
}

class AccountBreadcrumbs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      useHiddenMenu: false,
      accountHierarchy: null,
      dropdownOpen: false
    };
    this.breadCrumbRef = React.createRef();
    this.currentAccountRef = React.createRef();
    this.changeAccount = this.changeAccount.bind(this);
    this.createShortenedList = this.createShortenedList.bind(this);
    this.createList = this.createList.bind(this);
    this.setAccountHierarchy = this.setAccountHierarchy.bind(this);
  }

  changeAccount(e, accId) {
    e.preventDefault();
    this.setState({
        dropdownOpen: false,
        useHiddenMenu: false,
        accountHierarchy: null
    }, () => {

        /**
         * Don't set the base account, rather let the
         * componentDidUpdate in BaseAccount handle this based on changes in URI props.
         */
        this.props.history.push("/accounts/" + accId);
    })
  }

  makeLinkComponent(account) {
    var self = this;

    let childItemStyle = {
      width: "-webkit-fill-available",
      overflow: "hidden",
      textOverflow: "ellipsis",
    };

    if (self.props.useLinks) {
      return (
        <Link onClick={self.props.onClickCb} to={"/accounts/" + account.Id}>
          {account.Name}
        </Link>
      );
    } else {
      return (
        <button
          style={childItemStyle}
          className="btn btn-link"
          title={account.Name}
          onClick={(e) => self.changeAccount(e, account.Id)}
        >
          {account.Name}
        </button>
      );
    }
  }

  setAccountHierarchy() {    
    let self = this;
    if (
      this.props.accountHierarchy === undefined ||
      this.props.accountHierarchy.length === 0
    ) {
      return null;
    }

    // Ensure we don't fiddle the original hierarchy
    var ah = this.props.accountHierarchy.slice(0);

    /**
     * Hierarchy is done from base account, so for customer portal we need
     * to add our end account in to the list
     */
    if (
      this.props.account !== undefined &&
      this.props.account !== null &&
      this.props.account.hasOwnProperty("Id")
    ) {
      var last = ah.slice(-1).pop();
      if (last !== undefined && last.Id !== this.props.account.Id) {
        ah.push(this.props.account);
      }
    }
    self.setState({ accountHierarchy: ah, dropdownOpen: false }, () => {

      let useHiddenMenu = false;
      /**
       * If there are more than 2 nested accounts and the current or last account name is truncated, then use the shortened ellipses menu
       */
      if(self.currentAccountRef.current && self.currentAccountRef.current.offsetWidth < self.currentAccountRef.current.scrollWidth && self.state.accountHierarchy.length > 2) {
        useHiddenMenu = true;
      }
      if(self.state.useHiddenMenu != useHiddenMenu){
        self.setState({ useHiddenMenu: useHiddenMenu });
      }
    });
  }

  componentDidMount() {
    let self = this;
    self.setAccountHierarchy();
  }

  componentDidUpdate(prevProps, prevState) {
    var self = this;

    /**
     * If current account id not the same, recalculate header
     */
    if(prevProps.account.Id != self.props.account.Id 
      || (prevProps.baseAccount && self.props.baseAccount && prevProps.baseAccount.Id != self.props.baseAccount.Id)
      || (prevProps.contractAccount && self.props.contractAccount && prevProps.contractAccount.Id != self.props.contractAccount.Id)) {
        self.setAccountHierarchy();
    }

  }

  createShortenedList = () => {
    let self = this;

    let result = {
        accountList: [],
        leftArrow: null,
    }

    if(!self.state.accountHierarchy || !self.state.useHiddenMenu) {
        return result
    }

    let itemStyle = {
      maxWidth: `30%`,
      paddingRight: "20px",
    };
    result.accountList.push(
      <li
        key={`shortlist_${self.state.accountHierarchy[0].Id}}`}
        style={itemStyle}
        className="breadcrumb-item"
      >
        {self.makeLinkComponent(self.state.accountHierarchy[0])}
      </li>
    );
    result.accountList.push(
      <li
        key={`shortlist_ellipses-button`}
        style={itemStyle}
        className="breadcrumb-item"
      >
        <button 
            className={"btn btn-link"} 
            onClick={() => self.setState({ dropdownOpen: !self.state.dropdownOpen})}
            type="button" 
        >
                ...
        </button>
        <div className={`hiddenBreadcrumbsMenu dropdown-menu`} style={{display: self.state.dropdownOpen ? 'block' : 'none'}}>
            <ul>
                {self.state.accountHierarchy.map((account, i) => 
                {
                    if(i === 0 || i === self.state.accountHierarchy.length - 1) {
                        return null
                    }

                    return(
                        <li
                            key={account.Id}
                            className="breadcrumb-item-hidden"
                        >
                            {self.makeLinkComponent(account)}
                        </li>
                    );
                }
                )}
            </ul>
        </div>
      </li>
    );
    result.accountList.push(
      <li
      key={`shortlist_${self.state.accountHierarchy[self.state.accountHierarchy.length - 1].Id}`}
        style={{
          width: `${Math.floor(100 / self.state.accountHierarchy.length)}%`,
          flex: "1 1 auto",
          paddingRight: "20px",
        }}
        className="breadcrumb-item active"
      >
        <button
          className="accountName btn btn-link"
          style={{
            width: "-webkit-fill-available",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
          title={self.state.accountHierarchy[self.state.accountHierarchy.length - 1].Name}
          ref={self.currentAccountRef}
        >
          {self.state.accountHierarchy[self.state.accountHierarchy.length - 1].Name}
        </button>
      </li>
    );
    result.leftArrow = (
      <button
        className="btn btn-default btn-default-tight"
        onClick={(e) => self.changeAccount(e, self.state.accountHierarchy[self.state.accountHierarchy.length - 2].Id)}
      >
        <i className="fa-solid fa-arrow-left"></i>
      </button>
    );
    return result;
  };

  createList = () => {
    var self = this;
    
    let result = {
      accountList: [],
      leftArrow: null,
    };

    if(!self.state.accountHierarchy || self.state.useHiddenMenu){
        return result
    }

    let itemStyle = {
      maxWidth: `${Math.floor(100 / self.state.accountHierarchy.length)}%`,
      paddingRight: "20px",
    };
    self.state.accountHierarchy.forEach(function (account, index) {
      var ele = null;
      if (index === self.state.accountHierarchy.length - 1) {
        ele = (
          <li
            key={account.Id}
            style={{
              width: `${Math.floor(100 / self.state.accountHierarchy.length)}%`,
              flex: "1 1 auto",
              paddingRight: "20px",
            }}
            className="breadcrumb-item active"
            title={account.ExternalId}
          >
            {self.props.linkLeaf ? (
              self.makeLinkComponent(account)
            ) : (
              <button
                className="accountName btn btn-link"
                style={{
                  width: "-webkit-fill-available",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
                title={account.Name}
                ref={self.currentAccountRef}
              >
                {account.Name}
              </button>
            )}
          </li>
        );
      } else if (index === self.state.accountHierarchy.length - 2) {
        ele = (
          <li
            key={account.Id}
            style={itemStyle}
            className="breadcrumb-item"
            title={account.ExternalId}
          >
            {self.makeLinkComponent(account)}
          </li>
        );
        result.leftArrow = (
          <button
            className="btn btn-default btn-default-tight"
            onClick={(e) => self.changeAccount(e, account.Id)}
          >
            <i className="fa-solid fa-arrow-left"></i>
          </button>
        );
      } else {
        ele = (
          <li
            key={account.Id}
            style={itemStyle}
            className="breadcrumb-item"
            title={account.ExternalId}
          >
            {self.makeLinkComponent(account)}
          </li>
        );
      }
      result.accountList.push(ele);
    });

    if (self.props.breadcrumbsOnly) {
      result.leftArrow = null;
    }
    return result;
  };

  render() {
    var self = this;
    if (!self.state.accountHierarchy) {
      return null;
    }

    let { leftArrow, accountList } = self.state.useHiddenMenu ? self.createShortenedList() : self.createList();

    return (
      <div
        className={
          "container breadcrumbs-wrapper " +
          (self.props.className ? self.props.className : "")
        }
      >
        {leftArrow}
        <div className="breadcrumbs">
          <ol className="breadcrumb" ref={self.breadCrumbRef}>
            {accountList}
          </ol>
        </div>
      </div>
    );
  }
}
const mapStateToProps = state => {
  const account = state.account;
  return {
      baseAccount: account.baseAccount,
      contractAccount: account.contractAccount,
  };
}
const mapDispatchToProps = (dispatch) => {
  return {
  }
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AccountBreadcrumbs));