import React, { Component } from 'react';
import { Formik } from 'formik';
import { cmsGetPromise, cmsGetChildAccountsPromise, getErrMsg } from '../CallMSAPI.js';
import SearchAccountBreadcrumbs from '../SearchAccountBreadcrumbs';

import { toast } from 'react-toastify';
import {
    Modal,
    mergeStyleSets,
} from 'office-ui-fabric-react';
import { connect } from 'react-redux';

var _ = require('lodash');

const SearchLimit = 10;

const contentStyles = mergeStyleSets({
    container: {
        display: 'flex',
        flexFlow: 'column nowrap',
        alignItems: 'stretch',
    },
    body: {
        width: '500px',
        flex: '4 4 auto',
        padding: '0 24px 24px 24px',
        overflowY: 'hidden',
        selectors: {
            p: { margin: '14px 0' },
            'p:first-child': { marginTop: 0 },
            'p:last-child': { marginBottom: 0 },
        },
    },
});

class AccountsSearch extends Component {

    constructor(props) {
        super(props);

        this.state = {
            lastSearch: '',
            data: [],
            RecordCount: 0,
            loading: false
        }

        this.hideModal = this.hideModal.bind(this);
    }

    hideModal() {
        var self = this;
        self.setState({
            lastSearch: '',
            data: []
        })
    }

    render() {
        var self = this;

        var resultsModal = null;
        var list = [];
        if (self.state.lastSearch) {
            _.forEach(self.state.data, function (d) {
                list.push(
                    <tr key={d.Id}>
                        <td>
                            <SearchAccountBreadcrumbs
                                className="search-crumbs"
                                account={d}
                                accountHierarchy={d.Path}
                                breadcrumbsOnly={true}
                                linkLeaf={d.NumChildren > 0}
                                useLinks={true}
                                onClickCb={self.hideModal}
                            />
                        </td>
                        <td> <a href={"/portal/" + d.Id + "/users"}>Customer Portal</a></td>
                    </tr>
                );
            });

            var limitMsg = null;
            if (self.state.RecordCount > SearchLimit) {
                limitMsg = <p>Showing first 10 results of {self.state.RecordCount}</p>;
            }

            resultsModal = (
                <Modal
                    isOpen={true}
                    onDismiss={self.hideModal}
                    isBlocking={false}
                    containerClassName={contentStyles.container}
                >
                    <div className="search-results-wrapper">
                        <h3>Search Results for <em>{self.state.lastSearch}</em></h3>
                        {limitMsg}
                        <table className="table">
                            <tbody>
                                {list.length
                                    ? <><tr>
                                        <th>Name</th>
                                        <th>Portal Link</th>
                                    </tr>{list}
                                    </>
                                    : <tr><td>No results</td></tr>
                                }
                            </tbody>
                        </table>
                        <hr />
                        <button className="btn text-right" onClick={self.hideModal}>Close</button>
                    </div>
                </Modal>
            );
        }

        return (
            <>
                <Formik
                    initialValues={{ name: '' }}
                    validate={values => {
                        const errors = {};
                        // Silent, stop form submission but on this particular form we don't show 'required'
                        if (!values.name) { errors.name = 'Required'; }
                        return errors;
                    }}
                    onSubmit={(values, { setSubmitting, resetForm }) => {
                        self.setState({ loading: true }, function () {
                            cmsGetChildAccountsPromise(self.props.account.Id, SearchLimit, values.name)
                                .then(function (results) {

                                    var accPromises = [];
                                    var searchData;
                                    var recordCount = 0;
                                    if (results && results.data && results.data.Results) {
                                        searchData = results.data.Results;
                                        recordCount = results.data.RecordCount;

                                        _.forEach(results.data.Results, function (acc) {
                                            accPromises.push(
                                                cmsGetPromise(
                                                    { accountId: self.props.account.Id, objectType: 'accountpath', objectId: acc.Id }
                                                )
                                            );
                                        });
                                    }

                                    return Promise.allSettled(accPromises).then(function (pathResults) {
                                        // array with value and data
                                        _.forEach(pathResults, function (i) {
                                            if (i && i.value && i.value.data) {
                                                var data = i.value.data;
                                                var leaf = i.value.data[i.value.data.length - 1];

                                                var ent = _.find(searchData, { Id: leaf.Id });
                                                if (ent) {
                                                    ent['Path'] = data;
                                                }
                                            }
                                        });

                                        return {
                                            Results: searchData,
                                            RecordCount: recordCount
                                        };
                                    });
                                })
                                .then(function (results) {
                                    var out = [];
                                    if (results && results.Results) {
                                        out = results.Results;
                                    }
                                    self.setState({
                                        data: out,
                                        loading: false,
                                        lastSearch: values.name,
                                        RecordCount: results.RecordCount
                                    });
                                    setSubmitting(false);
                                    resetForm();

                                }, function (err) {
                                    toast.error(getErrMsg(err));
                                    self.setState({
                                        data: [],
                                        RecordCount: 0,
                                        lastSearch: '',
                                        loading: false
                                    });
                                    setSubmitting(false);
                                });

                        });
                    }}
                >
                    {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        isSubmitting,
                        /* and other goodies */
                      }) => (
                            <form role="form" onSubmit={handleSubmit} className="form-inline account-search-form">
                                {self.state.loading ? <i className="fa-solid fa-spinner fa-spin"></i> : null}
                                <input
                                    type="name"
                                    name="name"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.name}
                                    className="form-control"
                                    placeholder="Search Hierarchy"
                                />
                                {false && errors.name && touched.name && errors.name}
                            </form>
                        )}
                </Formik>

                {resultsModal}
            </>
        );
    }
}
const mapStateToProps = state => {
    const account = state.account;
    return {
        account: account.account
    };
}
const mapDispatchToProps = (_dispatch) => {
    return {
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(AccountsSearch);
