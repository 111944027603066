import React, { useEffect, useState } from "react";
import { Formik, Field } from "formik";
import { useLocation } from "react-router-dom";
import axios from "axios";
var _ = require("lodash");
var md5 = require("md5");

const PbxKazoo = () => {
  const loc = useLocation();
  const [params, setParams] = useState();
  const [focus, setFocus] = useState("");

  useEffect(() => {
    let queryParams = loc.search.split("&");
    let name = decodeURI(queryParams.find((x) => x.includes("name"))?.split("=")[1]);
    let apiuri = queryParams.find((x) => x.includes("api_uri"))?.split("=")[1];
    if (apiuri && apiuri.includes("/v2/")) {
      apiuri = apiuri.replace("/v2/", "");
    }
    let devicetype = decodeURI(queryParams
      .find((x) => x.includes("devicetype"))
      ?.split("=")[1]);
    let devicenameprefix = decodeURI(queryParams
      .find((x) => x.includes("devicenameprefix"))
      ?.split("=")[1]);
    let includeunassigneddevices = queryParams
      .find((x) => x.includes("includeunassigneddevices"))
      ?.split("=")[1];

    let paramsOb = {
      name,
      apiuri,
      devicetype,
      devicenameprefix,
      includeunassigneddevices,
    };
    setParams(paramsOb);
  }, [loc.search]);

  return (
    <Formik
      // Required so updates from getFormDefaults get propagated down to form
      enableReinitialize={true}
      initialValues={{
        username: "",
        password: "",
        name: params && params.name ? params.name : "PBX Sync",
        accountName: "",
        apiUri: params && params.apiuri ? params.apiuri : "",
        deviceType: params && params.devicetype ? params.devicetype : "",
        deviceNamePrefix:
          params && params.devicenameprefix ? params.devicenameprefix : "",
        includeUnassignedDevices:
          params && params.includeunassigneddevices
            ? params.includeunassigneddevices
            : "",
      }}
      validate={(values) => {
        let errors = {};

        if (!values.deviceNamePrefix && !values.deviceType) {
          errors.deviceNamePrefix =
            "Unable to login, either Device Type or Device Name Prefix must be filled in";
        }

        return errors;
      }}
      onSubmit={(originalValues, { setSubmitting, setErrors, resetForm }) => {
        var formik = this;
        var values = _.cloneDeep(originalValues);
        setSubmitting(true);

        var data = {
          data: {
            account_name: values.accountName,
            credentials: md5(values.username + ":" + values.password),
          },
        };
        var url = values.apiUri;
        if (!url.startsWith("https://") && !url.startsWith("http://")) {
          url = "https://" + url;
        }
        if (!url.endsWith("/v2") && !url.endsWith("/v2/")) {
          url = url + "/v2/";
        }
        if (!url.endsWith("/")) {
          url = url + "/";
        }

        axios.put(url + 'user_auth', JSON.stringify(data)).then(response => {
          setSubmitting(false);
          resetForm();
          return window.location = `${window.location.origin}/pbxDummy.html?code=${response.auth_token}&apiuri=${url}&deviceType=${values.deviceType}&deviceNamePrefix=${values.deviceNamePrefix}&includeUnassignedDevices=${values.includeUnassignedDevices}`;
        }).catch(err => {
          setSubmitting(false);
          resetForm();
          if(err.message){
            alert(err.message);
          } else {
            alert("Unable to login, please check your credentials");
          }
        })
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        setFieldValue,
        /* and other goodies */
      }) => {
        return (
          <div className="kazoo_auth">
            <div className="content">
              <h1 id="accServiceHeading">{values.name}</h1>
              <form role="form" id="frmSignIn" onSubmit={handleSubmit}>
                <span className="error-message">{errors.deviceNamePrefix}</span>
                <div className="form-input-wrapper">
                  <input
                    type="text"
                    id="username"
                    className={focus === "username" ? "input-active" : ""}
                    name="username"
                    value={values.username}
                    onChange={handleChange}
                    onFocus={() => setFocus("username")}
                    onBlur={() => {
                      if (focus === "username") {
                        setFocus("");
                      }
                    }}
                    required
                  />
                  <span
                    className={
                      focus === "username"
                        ? "placeholder-shift placeholder-shift-fixed placeholder-shift-active"
                        : values.username
                        ? "placeholder-shift placeholder-shift-fixed placeholder-shift-completed"
                        : "placeholder-shift"
                    }
                  >
                    Username
                  </span>
                </div>
                <div className="form-input-wrapper">
                  <input
                    type="password"
                    id="password"
                    name="password"
                    className={focus === "password" ? "input-active" : ""}
                    value={values.password}
                    onChange={handleChange}
                    onFocus={() => setFocus("password")}
                    onBlur={() => {
                      if (focus === "password") {
                        setFocus("");
                      }
                    }}
                    required
                  />
                  <span
                    className={
                      focus === "password"
                        ? "placeholder-shift placeholder-shift-fixed placeholder-shift-active"
                        : values.password
                        ? "placeholder-shift placeholder-shift-fixed placeholder-shift-completed"
                        : "placeholder-shift"
                    }
                  >
                    Password
                  </span>
                </div>
                <div className="form-input-wrapper">
                  <input
                    type="text"
                    id="accountName"
                    name="accountName"
                    className={focus === "accountName" ? "input-active" : ""}
                    value={values.accountName}
                    onChange={handleChange}
                    onFocus={() => setFocus("accountName")}
                    onBlur={() => {
                      if (focus === "accountName") {
                        setFocus("");
                      }
                    }}
                    required
                  />
                  <span
                    className={
                      focus === "accountName"
                        ? "placeholder-shift placeholder-shift-fixed placeholder-shift-active"
                        : values.accountName
                        ? "placeholder-shift placeholder-shift-fixed placeholder-shift-completed"
                        : "placeholder-shift"
                    }
                  >
                    Account Name
                  </span>
                </div>
                {params && !params.apiuri && (
                  <div id="api-uri-container">
                    <div className="form-input-wrapper">
                      <input
                        type="text"
                        id="apiUri"
                        name="apiUri"
                        className={focus === "apiUri" ? "input-active" : ""}
                        required
                        value={values.apiUri}
                        onChange={handleChange}
                        onFocus={() => setFocus("apiUri")}
                        onBlur={() => {
                          if (focus === "apiUri") {
                            setFocus("");
                          }
                        }}
                      />
                      <span
                        className={
                          focus === "apiUri"
                            ? "placeholder-shift placeholder-shift-fixed placeholder-shift-active"
                            : values.apiUri
                            ? "placeholder-shift placeholder-shift-fixed placeholder-shift-completed"
                            : "placeholder-shift"
                        }
                      >
                        Api Uri
                      </span>
                    </div>
                  </div>
                )}
                {params && !params.devicetype && (
                  <div id="device-type-container">
                    <div className="form-input-wrapper">
                      <input
                        type="text"
                        id="deviceType"
                        name="deviceType"
                        className={focus === "deviceType" ? "input-active" : ""}
                        value={values.deviceType}
                        onChange={handleChange}
                        onFocus={() => setFocus("deviceType")}
                        onBlur={() => {
                          if (focus === "deviceType") {
                            setFocus("");
                          }
                        }}
                      />
                      <span
                        className={
                          focus === "deviceType"
                            ? "placeholder-shift placeholder-shift-fixed placeholder-shift-active"
                            : values.deviceType
                            ? "placeholder-shift placeholder-shift-fixed placeholder-shift-completed"
                            : "placeholder-shift"
                        }
                      >
                        Device Type
                      </span>
                    </div>
                  </div>
                )}
                {params && !params.devicenameprefix && (
                  <div id="device-name-prefix-container">
                    <div className="form-input-wrapper">
                      <input
                        type="text"
                        id="deviceNamePrefix"
                        name="deviceNamePrefix"
                        value={values.deviceNamePrefix}
                        className={
                          focus === "deviceNamePrefix" ? "input-active" : ""
                        }
                        onChange={handleChange}
                        onFocus={() => setFocus("deviceNamePrefix")}
                        onBlur={() => {
                          if (focus === "deviceNamePrefix") {
                            setFocus("");
                          }
                        }}
                      />
                      <span
                        className={
                          focus === "deviceNamePrefix"
                            ? "placeholder-shift placeholder-shift-fixed placeholder-shift-active"
                            : values.deviceNamePrefix
                            ? "placeholder-shift placeholder-shift-fixed placeholder-shift-completed"
                            : "placeholder-shift"
                        }
                      >
                        Device Name Prefix
                      </span>
                    </div>
                  </div>
                )}
                {params && !params.includeunassigneddevices && (
                  <div id="include-unassigned-devices-container">
                    <div className="form-input-group-wrapper">
                      <label htmlFor="yes">
                        <Field
                          type="radio"
                          name="includeUnassignedDevices"
                          value="yes"
                        />
                        Yes
                      </label>
                      <label htmlFor="no">
                        <Field
                          type="radio"
                          name="includeUnassignedDevices"
                          value="no"
                        />
                        No
                      </label>
                      <span className="radio-label">
                        Include Unassigned Devices
                      </span>
                    </div>
                  </div>
                )}
                <div className="form-signin-wrapper">
                  <button type="submit" className="form">
                    Sign in
                  </button>
                </div>
              </form>
            </div>
          </div>
        );
      }}
    </Formik>
  );
};
export default PbxKazoo;
