import React, { Component } from 'react';
import { toast } from 'react-toastify';
import { Formik } from 'formik';
import { SubmitButton } from '../../FormHelpers';
import { cmsGetPromise, cmsPostPromise, cmsDeletePromise, getErrMsg } from '../../CallMSAPI.js';
import { connect } from 'react-redux';
import * as actions from '../../store/actions/index';

const validFileTypes = [".gif", ".jpg", ".jpeg", ".png", ".icon"];
var _ = require('lodash');

class BrandingImageForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            image: null,
            type: '',
            size: 0,
            width: 0,
            height: 0,
            isDeleteEnabled: false
        };


        this.uploadId = _.uniqueId('upload_');

        this.onSelectFile = this.onSelectFile.bind(this);
    }

    componentDidMount() {
        this.downloadImage();
    }

    downloadImage = () => {
        var self = this;

        cmsGetPromise({
            accountId: self.props.account.Id,
            objectType: self.props.objectType,
        }).then(response => {
            var image = `data:${response.data.ContentType};base64,${response.data.Image}`;

            self.getImageDimensions(image).then(dimensions => {
                self.setState({
                    image: image,
                    type: response.data.ContentType,
                    size: response.data.Image.length,
                    width: dimensions.width,
                    height: dimensions.height,
                    isDeleteEnabled: true
                })
            })
        })
    }

    getImageDimensions = (file) => {
        return new Promise(function (resolved) {
            var image = new Image()
            image.onload = function () {
                resolved({ width: image.width, height: image.height })
            };
            image.src = file
        })
    }

    isValidFileType = (type) => {
        return type && validFileTypes.includes(type.replace("image/", "."));
    }

    isValidFileSize = (size) => {
        return size && !((size / Math.pow(1024, 2.0)) > 1);
    }

    onSelectFile = (e) => {
        var self = this;
        if (e.target.files
            && e.target.files.length > 0
            && this.isValidFileType(e.target.files[0].type)) {

            var file = e.target.files[0];
            const reader = new FileReader();

            reader.addEventListener('load', () => {
                self.getImageDimensions(reader.result).then(dimensions => {
                    self.setState({
                        image: reader.result,
                        type: file.type,
                        size: file.size,
                        width: dimensions.width,
                        height: dimensions.height,
                    })
                })
            });

            reader.readAsDataURL(file);
        }
    };

    onDeleteImage = () => {
        var self = this;

        cmsDeletePromise({
            accountId: self.props.account.Id,
            objectType: self.props.objectType,
        }).then(
            function () {
                toast.success("Image successfully deleted.");

                self.props.refreshAccount(() => {
                    self.setState({
                        image: null,
                        type: '',
                        size: 0,
                        isDeleteEnabled: false
                    });
                    document.getElementById(self.uploadId).value = '';
                });

            }, function (error) {
                toast.error(getErrMsg(error));
            }
        )
    };

    render = () => {
        var self = this;

        return (
            <>
                <div className="row">
                    <div className="col-md-12">
                        <h2>{self.props.header}</h2>
                    </div>
                </div>
                <Formik
                    enableReinitialize={true}
                    initialValues={self.state}
                    validate={values => {
                        let errors = {};

                        if (values.image === null) {
                            errors.image = 'You must select an image.';
                        } else if (!self.isValidFileType(values.type)) {
                            errors.image = `Please select a valid file type (${validFileTypes.join(" ")}).`;
                        } else if (!self.isValidFileSize(values.size)) {
                            errors.image = 'Inavlid file size, maximum limit is 1MB.';
                        }

                        return errors;
                    }}

                    onSubmit={(originalValues, { setSubmitting }) => {
                        var values = _.cloneDeep(originalValues);

                        cmsPostPromise({
                            accountId: self.props.account.Id,
                            Image: values.image.replace(/^data:.+;base64,/, ''),
                            objectType: self.props.objectType,
                        }).then(
                            function () {
                                setSubmitting(false);
                                toast.success("Image successfully uploaded.");

                                self.props.refreshAccount(() => {
                                    self.setState({
                                        isDeleteEnabled: true
                                    });
                                });

                            }, function (error) {
                                setSubmitting(false);
                                toast.error(getErrMsg(error));
                            }
                        )
                    }}>
                    {({
                        values,
                        errors,
                        handleSubmit,
                        isSubmitting,
                    }) => {
                        return (
                            <>
                                <form role="form" className="form form-company-logo" onSubmit={handleSubmit}>
                                    <fieldset disabled={isSubmitting}>
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="alert alert-info domain-form-wrapper">
                                                    <div className="row">
                                                        <div className={values.image ? "col-md-6" : "col-md-12"}>
                                                            {values.image
                                                                ?
                                                                <>
                                                                    <h4>Preview</h4>
                                                                    <div className="logo-container">
                                                                        {values.image
                                                                            ? <img className="img-logo" src={values.image} alt="no image" />
                                                                            : <div className="no-image">no image</div>
                                                                        }
                                                                    </div>
                                                                    {values.image && <h5>Height: {self.state.height}px, Width: {self.state.width}px</h5>}
                                                                    {self.props.recommendations &&
                                                                        <div className="row">
                                                                            <div className="col-md-12">
                                                                                <p><em>{self.props.recommendations}</em></p>
                                                                            </div>
                                                                        </div>
                                                                    }
                                                                </>
                                                                : <p><em>{`You don't currently have a ${self.props.header.toLowerCase()} configured. Use the button below to get started and upload one.`}</em></p>
                                                            }
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-md-12">
                                                            <div className="form-group">
                                                                <div className="row">
                                                                    <div className="col-md-12">
                                                                        <input type="file" id={self.uploadId} name={self.uploadId} accept={`${validFileTypes.join(",")}`} onChange={self.onSelectFile} style={{ display: "none" }} />
                                                                        <label style={{ width: "200px", backgroundColor: "rgb(239, 239, 239)" }} className="btn mb-2" htmlFor={self.uploadId}>Choose Image To Upload</label>
                                                                    </div>
                                                                </div>
                                                                <div className="row">
                                                                    <div className="col-md-12">
                                                                        {errors.image &&
                                                                            <span className="error-message">{errors.image}</span>}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-sm-12">
                                                            {values.image && (
                                                                <SubmitButton className={"btn btn-primary mb-2 right-action-button"} isSubmitting={isSubmitting}>Save</SubmitButton>
                                                            )}
                                                            {self.state.isDeleteEnabled &&
                                                                <button type="button" className="btn btn-default"
                                                                    onClick={(e) => {
                                                                        e.preventDefault();
                                                                        self.onDeleteImage();
                                                                        return false;
                                                                    }}><i className="fa-solid fa-trash"></i>
                                                                </button>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </fieldset>
                                </form>
                            </>
                        )
                    }
                    }
                </Formik>
            </>
        )
    }
}
const mapStateToProps = state => {
    const account = state.account;
    return {
        account: account.account,
    };
}
const mapDispatchToProps = (dispatch) => {
    return {
        refreshAccount: (callBack = () => { }) => dispatch(actions.refreshAccount(callBack))
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(BrandingImageForm);
