import React from 'react';
import { TeachingBubble, Toggle } from '@fluentui/react';
import { object, func, bool } from 'prop-types';
import { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { getConsentForPhoneApp } from '../../MSALAuthProvider';
import { Icon } from 'office-ui-fabric-react/lib/Icon';
import { cmsGetAppSettings, getErrMsg, cmsGetPhoneAppSettings, cmsRecordConsentGranted } from '../../CallMSAPI';
import dayjs from '../../dayjs.js';
import NextGenPhoneAppRow from "./NextGenPhoneAppRow.js";

const CallingEssentialsRow = (props) => {

    const [appSettings, setAppSettings] = useState(null);
    useEffect(() => {
        cmsGetAppSettings().then((res) => {
            if (res && res.data) {
                setAppSettings(res.data);
            }
        })
    }, [])

    const [state, setState] = useState({
        showCallingEssentialsHelp: false,
        showEADHelp: false
    });

    const toggleCallingEssentialsHelp = () => {
        setState({ ...state, showCallingEssentialsHelp: !state.showCallingEssentialsHelp });
    }

    const dismissCallingEssentialsHelp = () => {
        setState({ ...state, showCallingEssentialsHelp: false });
    }

    const toggleEADHelp = () => {
        setState({ ...state, showEADHelp: !state.showEADHelp });
    }

    const dismissEADHelp = () => {
        setState({ ...state, showEADHelp: false });
    }

    const teamsSettings = props.fullService.TeamsSettings;

    const formatDate = (date) => {
        if (date == null) return '';
        // Date is stored as UTC so convert to local time and format
        return dayjs.utc(date).local().format('DD MMMM YYYY HH:mm')
    }

    const renderAccessConsent = () => {
        if (props.fullService && props.values && props.values.SyncEnabled) {
            return (
                <div className='col-md-12'>
                    <div className='row'>
                        <div style={{display:'inline-block', marginRight: '1rem', float: 'left'}}>
                            <button className='btn btn-primary my-3' onClick={() => {
                                cmsGetPhoneAppSettings().then(function (res) {
                                    if (res && res.data) {
                                        let clientId = res.data.clientId;
                                        getConsentForPhoneApp(clientId).then(function (res) {
                                            if (res) {
                                                toast.success('Consent for Phone App granted successfully')
                                                cmsRecordConsentGranted(props.account.Id, props.currentService.Id, "PhoneAppUi").then(() => {
                                                    props.refreshFullService();
                                                });
                                            }
                                        });
                                    }
                                }).catch(function (err) {
                                    toast.error('Consent for Phone App not granted: ' + getErrMsg(err.message));
                                })
                            }}>
                                Authorize Phone App for my organization
                            </button>
                            { teamsSettings && teamsSettings.PhoneAppLastAuthorisedDate != null &&
                                <div><i class="fa-regular fa-circle-check icon-green"></i> Last Authorized: {formatDate(teamsSettings.PhoneAppLastAuthorisedDate)}</div>
                            }
                        </div>
                        <div style={{display:'inline-block', marginLeft: '1rem'}}>
                            <button className='btn btn-primary my-3' onClick={() => {
                                getConsentForPhoneApp(appSettings.phoneAppBotRegistrationId).then((res) => {
                                    if (res) {
                                        toast.success('Consent for Phone App Bot granted successfully')
                                        // After successfully getting consent then call setup phone app v3 endpoint
                                        if (props.currentService) {
                                            cmsRecordConsentGranted(props.account.Id, props.currentService.Id, "PhoneAppBot").then(() => {
                                                toast.warning('Sync now to complete PhoneApp setup');
                                                props.refreshFullService();
                                            })
                                            .catch((err) => {
                                                toast.error('Error during configuration, please try again');
                                            })                                            
                                        }
                                    }
                                    return true
                                }).catch(function (err) {
                                    toast.error('Consent for Phone App Bot not granted: ' + getErrMsg(err.message));
                                })
                                }}>
                                Authorize Phone App Bot for my organization
                            </button>
                            { teamsSettings && teamsSettings.PhoneAppBotLastAuthorisedDate != null &&
                                <div><i class="fa-regular fa-circle-check icon-green"></i> Last Authorized: {formatDate(teamsSettings.PhoneAppBotLastAuthorisedDate)}</div>
                            }
                        </div>
                    </div>
                </div>
            );
        }

        return null;
    }

    const renderSBCWarning = () => {

        let isLegacyPBX = false;
        let sbcServiceList = [];
        if (props.fullService && props.fullService.SBCs && props.fullService.SBCs.length > 0) {
            sbcServiceList = props.fullService.SBCs.map(x => x.Domain);
        }
        let legacySBCs = [
            "aauea04",
            "aeuwa04",
            "aeuwa05",
            "ausea04",
            "beuwb02",
            "beuwc01",
            "beuwc02",
            "buseb01"
        ];
        for (let i = 0; i < legacySBCs.length; i++) {
            let leg = legacySBCs[i];
            let match = sbcServiceList.find(x => x.toLowerCase().includes(leg));
            if (match) {
                isLegacyPBX = true;
                break;
            }
        }

        return (
            <>
                {props.values.PhoneAppToggle && isLegacyPBX &&
                    <div className="row sbc-warning">
                        <div className="col-md-12 title">
                            <div className="sbc-warning-header">
                                <Icon iconName="Warning" className="sbc-warning-icon" />
                                <p className="sbc-warning-text">Warning</p>
                            </div>
                        </div>
                        <div className="col-md-12 sbc-warning-content">
                            <p className="sbc-warning-subheading">The SBC this account is assigned to is not compatible with Phone App
                            version 1.2 onwards. Please contact support via the widget a the top right
                            of the portal and raise a service request.
                            </p>
                            <p className="sbc-warning-subheading">If you wish to have more than
                                one account enabled for Phone App, please list these within the
                                service request
                            </p>
                            <div className="sbc-warning-subheading">Please note</div>
                            <div>
                            If the account/s have whitelisted IPs for the respective PBX, please
                            include this within the service request, as a change of SBC will result
                            in different IP addresses being used.
                            </div>
                        </div>
                    </div>
                }
            </>
        );
    }

    const renderPhoneAppOnly = () => {
        if (!props.values.PhoneAppToggle) {
            return null;
        }

        return (
            <div>
                {props.fullService && props.fullService.TeamsSettings && props.fullService.TeamsSettings.IsPhoneAppEnabled === 'PhoneAppOnly' &&
                    <div className='col-md-6'>
                        <Toggle
                            label={(
                                <div>
                                    <label htmlFor='phoneAppOnlyToggleBtn'>Phone App Only</label>
                                </div>
                            )}
                            id='phoneAppOnlyToggleBtn'
                            defaultChecked={props.values.PhoneAppOnlyToggle}
                            onText='On'
                            offText='Off'
                            onChange={((_e, checked) => props.setFieldValue('PhoneAppOnlyToggle', checked))}
                            disabled={props.disabled}
                        />
                    </div>
                }
                {renderAccessConsent()}
            </div>
        );
    }

    return (
        <>
            <div className='row' >
                <div className='col-md-3'>
                    <Toggle
                        label={(
                            <div>
                                <label htmlFor='phoneAppHelpBtn'>Phone App</label>
                                <button
                                    id='phoneAppHelpBtn'
                                    className='btn btn-link btn--faux-link'
                                    onClick={(e) => { e.preventDefault(); toggleCallingEssentialsHelp() }}
                                >
                                    <i className='fa-solid fa-question-circle' id={'callingEssentialsHelp'} />
                                </button>
                                {state.showCallingEssentialsHelp &&
                                    <TeachingBubble
                                        target={'#callingEssentialsHelp'}
                                        hasCondensedHeadline={true}
                                        onDismiss={dismissCallingEssentialsHelp}
                                        hasCloseIcon={true}
                                        closeButtonAriaLabel='Close'
                                        headline={'Phone App'}
                                    >
                                        <>
                                            <p>Enable the Phone App Teams user app to make and receive calls.</p>
                                            <p>This feature is disabled for users with Microsoft Phone System license.</p>
                                        </>
                                    </TeachingBubble>
                                }
                            </div>
                        )}
                        defaultChecked={props.values.PhoneAppToggle}
                        onText='On'
                        offText='Off'
                        onChange={((_e, checked) => {
                            if (!checked) {
                                props.setFieldValue('PhoneAppOnlyToggle', checked);
                            }
                            props.setFieldValue('PhoneAppToggle', checked)
                        })}
                        disabled={props.disabled}
                    />
                </div>
                <div className="col-sm-3">
                    {props.values.PhoneAppToggle ? (
                    <NextGenPhoneAppRow
                        account={props.account}
                        values={props.values}
                        errors={props.errors}
                        touched={props.touched}
                        setFieldValue={props.setFieldValue}
                        handleSubmit={props.handleSubmit}
                        setPostSaveCallback={props.setPostSaveCallback}
                    />
                    ) : null}
                </div>
                <div className="col-sm-6"></div>
                <div className="col-sm-12">{renderPhoneAppOnly()}</div>
            </div>
            {renderSBCWarning()}
        </>
    );
}
CallingEssentialsRow.defaultProps = {
    values: {
        PhoneAppToggle: false,
        PhoneAppOnlyToggle: false
    },
    fullService: {
        TeamsSettings: {
            IsPhoneAppEnabled: ''
        }
    },
    setFieldValue: () => { },
    disabled: false,
    account: {
        BrandDomain: ''
    }
};
CallingEssentialsRow.propTypes = {
    values: object.isRequired,
    fullService: object.isRequired,
    setFieldValue: func.isRequired,
    disabled: bool,
    account: object.isRequired
}
export default CallingEssentialsRow;