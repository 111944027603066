import React, { Component } from 'react';
import './AllApp.css';
import { NavLink, Redirect, Route, Switch } from 'react-router-dom'
import Header from './Header';
import Footer from './Footer';
import { canAccess, isSystemOwner, isOnlyBrandContributor } from './CallMSUIHelpers.js';
import EditBrandingTemplateForm from './Accounts/Branding/EditBrandingTemplateForm';
import AddBrandingTemplateForm from './Accounts/Branding/AddBrandingTemplateForm';
import Branding from './Accounts/Branding/Branding';
import Billing from './Accounts/Billing/Billing';
import Trials from './Trials';
import Accounts from './Accounts';

import { connect } from 'react-redux';

var _ = require('lodash');

class AppAccounts extends Component {

    isAccountsListingActive(match, location) {
        var matched = location.pathname.match(/\/accounts(\/[A-Za-z0-9]*)?\/?$/);
        // console.log('Checking ' + location.pathname + ' => ' + (matched ? 'Y' : 'N'));
        return matched;
    }

    hasBrandingFeatures = (account, roles) => {
        let features = [
            'SUPPORTLINKS',
            'FOOTERLINKS',
            'PORTALLOGO',
            'PORTALURL',
            'PBXTEMPLATES',
            'HIDETEMPLATES'];

        return     roles
                && _.find(roles, { 'Name': 'Brand Contributor' })
                && account
                && account.hasOwnProperty('Features')
                && account.Features.length > 0
                && _.find(account.Features, x => _.includes(features, x))
    }

    renderRoutesByRoles = () => { 
        let routes = [
            <Route
                key={2}
                path='/accounts/:accountId/branding/templates/edit/:templateId'
                component={EditBrandingTemplateForm}
            />,
            <Route
                key={3}
                path='/accounts/:accountId/branding/templates/add/:serviceVariantCode'
                component={AddBrandingTemplateForm}
            />,
            <Route
                key={0}
                path='/accounts/:accountId/branding'
                component={Branding}
            />
        ]

        if (isOnlyBrandContributor(this.props.account.roles)) {
            routes.push(<Redirect key={1} to={`/accounts/${this.props.baseAccount.Id}/branding`} />);
        } else {
            routes.push.apply(routes, [
                <Route
                    key={4}
                    path='/accounts/:accountId/billing'
                    component={Billing}
                />,
                <Route
                    key={5}
                    path='/accounts/:accountId/trials'
                    component={Trials}
                />,
                <Route
                    key={6}
                    path='/accounts/:accountId?'
                    component={Accounts}
                />
            ]);
        } 

        return (
            <Switch>
                {routes}
            </Switch>
        );
    }

    renderRoutes = () => {
        return (
            <Switch>
                {this.renderRoutesByRoles()}
            </Switch>
        );
    }

    render() {
        var links = [<NavLink key='accounts' activeClassName='current' to={`/accounts/${this.props.account.Id}`} isActive={this.isAccountsListingActive}> Accounts</ NavLink>];

        if (isOnlyBrandContributor(this.props.baseAccount.roles)) {
            links = [];
        }

        if (
            (
                ((this.props.baseAccount.roles && canAccess('Trials', this.props.baseAccount.roles))
                    || (this.props.accountRoles && canAccess('Trials', this.props.account.roles))
                )
            )
        ) {
            links.push(
                <NavLink key='trialspage' activeClassName='current' to={'/accounts/' + this.props.contractAccount.Id + '/trials'}>Trials</NavLink>
            );
        }

      // Not everyone gets the Billing tab
      // 1. Your base account gives you sub viewer (we manually prop. that down)
      // 2. Your current account gives you sub viewier
      // 3. You are system
      if (
          (
              ((this.props.baseAccount.roles && canAccess('Billing', this.props.baseAccount.roles))
                || (this.props.account.roles     && canAccess('Billing', this.props.account.roles))
              )
              && this.props.contractAccount.info
              && this.props.contractAccount.info.AccountType
              && this.props.contractAccount.info.AccountType.indexOf('Contract') > -1
          )

          || (canAccess('BillingAsSystem', this.props.baseAccountInfo.Roles) && this.props.contractAccount)
      ) {
          links.push(
              <NavLink key='billingpage' activeClassName='current' to={'/accounts/' + this.props.contractAccount.Id + '/billing'}>Billing</NavLink>
          );
        }

        if (this.hasBrandingFeatures(this.props.account, this.props.account.roles)) {
            links.push(
                <NavLink key='branding' activeClassName='current' to={'/accounts/' + this.props.account.Id + '/branding'}>Branding</NavLink>
            );
        } else if (isSystemOwner(this.props.baseAccount.roles)){
            links.push(
                <NavLink key='branding' activeClassName='current' to={'/accounts/' + this.props.account.Id + '/branding'}>Branding <i className='fa-solid fa-user-secret' title='System Owner Only'></i></NavLink>
            );
        }

        return (
            <div className='page-wrapper'>
                <Header links={links} />

                <br />
                <div className='container container--main-content' role='main'>

                    <div className='col-md-12'>
                        <div className='row'>
                            {this.renderRoutes()}
                        </div>
                    </div>
                </div>

                <Footer />

            </div>
        );
    }
}
const mapStateToProps = state => {
    const account = state.account;
    return {
        account: account.account,
        baseAccount: account.baseAccount,
        baseAccountInfo: account.baseAccountInfo,
        contractAccount: account.contractAccount
    };
}
const mapDispatchToProps = (_dispatch) => {
    return {
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(AppAccounts);
