import React, { Component } from 'react';
import { getErrMsg, cmsUpdateService } from '../CallMSAPI.js';
import { toast } from 'react-toastify';
import { Formik, Field, ErrorMessage } from 'formik';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { SubmitButton } from '../FormHelpers';

import { connect } from 'react-redux';
import * as actions from '../store/actions/index';

var _ = require('lodash');

class MicrosoftManualSteps extends Component {
    constructor(props) {
        super(props);
        this.state = {
            uniqueDomains: []
        };
    }

    componentDidMount() {
        var self = this;
        let domains = [];
        self.props.service.SBCs.forEach(function (sbc) {
            if(domains.length > 0){
                let match = domains.find(x => x.Domain === sbc.Domain);
                if(!match){
                    domains.push(sbc);
                }
            } else {
                domains.push(sbc);
            }
        });
        self.setState({uniqueDomains: domains})
    }

    safeFieldName(name) {
        return name.replace(/\./g, "_");
    }

    copySuccessClick(e, text) {
        e && e.preventDefault();
        toast.success(text + " copied to clipboard");
    }

    getDomainFormRow(service, sbc, errors) {
        var self = this;
        return (
            <tr key={sbc.Domain}>
                <td>
                    <CopyToClipboard text={sbc.Domain} onCopy={() => toast.success(sbc.Domain + " copied to clipboard")}>
                        <button className="btn btn-link btn-sm" onClick={(e) => { e.preventDefault() }}>
                            <span>{sbc.Domain}</span> <i className="fa-solid fa-copy"></i>
                        </button>
                    </CopyToClipboard>
                </td>
                <td>
                    <Field name={self.safeFieldName(sbc.Domain)} placeholder="MS=ms00000000" className="form-control form-control--ms-dns" />
                    <ErrorMessage name={self.safeFieldName(sbc.Domain)} />
                </td>
            </tr>
        );
    }

    getBaseValues() {
        var self = this;
        var base = {};

        this.props.service.SBCs.forEach(function (sbc) {
            base[self.safeFieldName(sbc.Domain)] = (sbc.ValidationCode ? sbc.ValidationCode : '');
        });

        return base;
    }

    render() {
        var self = this;

        var service = self.props.service;
        // var showFullForm = _.find(self.props.service.SBCs, function (sbc) { return sbc.ValidationCode === null }) ? true : false;

        if (  (service.Variant.Code === 'TEAMSNATIVESPDOM' || service.Variant.Code === 'TEAMSAUDIOCODES' || service.Variant.Code === 'TEAMSRIBBON')
            && service.SyncEnabled === false
            && self.props.service.SBCs
            && self.props.service.SBCs.length > 0
        ) {
            var fullForm = (
                <>
                    <p>Add the following domains to your Office365 tenant. If
                    offered a choice of validation methods choose the TXT record
                    method and copy the <em>TXT Value</em> into the matching domain
                    fields below.
                    </p>

                    <p>Once validated you can then use the following domains as
                    carrier PSTN Gateways in the <em>OnlinePstnGatewayList</em> parameter
                    of <em>New-CsOnlineVoiceRoute</em> when setting
                    up your Online Voice Route(s).</p>

                    <hr />

                    <Formik
                        enableReinitialize={true}
                        initialValues={self.getBaseValues()}
                        validate={values => {
                            let errors = {};
                            self.props.service.SBCs.forEach(function (sbc) {
                                if (!values[self.safeFieldName(sbc.Domain)] || values[self.safeFieldName(sbc.Domain)] === '') {
                                    // Fine... we allow one at a time to be added
                                    // errors[self.safeFieldName(sbc.Domain)] = 'This is a required field.';
                                } else if (!/^MS=ms\d+$/i.test(values[self.safeFieldName(sbc.Domain)])) {
                                    errors[self.safeFieldName(sbc.Domain)] = 'The value must be of the format MS=ms0000000';
                                }
                            })
                            return errors;
                        }}
                        onSubmit={(values, { setSubmitting }) => {
                            var outValues = {};
                            this.props.service.SBCs.forEach(function (sbc) {
                                outValues[sbc.Domain] = values[self.safeFieldName(sbc.Domain)];
                            });

                            cmsUpdateService(
                                self.props.account.Id,
                                service.Id,
                                { DomainValidationCodes: outValues },
                                function (ok) {
                                    setSubmitting(false);
                                    toast.success("Domain validation codes saved");

                                    self.props.refetchServices();
                                },
                                function (err) {
                                    setSubmitting(false);
                                    toast.success("Unable to save validation code. " + getErrMsg(err));
                                }
                            );
                        }}
                    >
                        {({
                            values,
                            errors,
                            touched,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                            isSubmitting,
                            /* and other goodies */
                          }) => {

                            var allRows = _.map(self.state.uniqueDomains, function (sbc) { return self.getDomainFormRow(service, sbc, errors) });
                            return (
                                <div className="row">
                                    <div className="col-md-8">
                                        <form role="form" className="form" onSubmit={handleSubmit}>
                                            <fieldset disabled={isSubmitting}>
                                            <table className="table table-fixed">
                                                <thead>
                                                    <tr>
                                                    <th>Domain / PSTN Gateway</th>
                                                    <th>TXT Value</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {allRows}
                                                </tbody>
                                            </table>
                                        <SubmitButton className={"btn btn-primary pull-right"} isSubmitting={isSubmitting}> Save </SubmitButton>
                                            </fieldset>
                                        </form>
                                    </div>
                                </div>
                            );
                         }}
                    </Formik>
                </>
            );

            return (
                <>
                    <hr />
                    <div className="row">
                    <div className="col-md-12">
                    <div className="alert alert-info">
                        {fullForm}
                    </div>
                    </div>
                    </div>
                </>
            );

        }

        return null;
    }
}
const mapStateToProps = state => {
    const account = state.account;
    return {
        account: account.account
    };
}
const mapDispatchToProps = (dispatch) => {
    return {
        refetchServices: () => dispatch(actions.refetchServices())
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(MicrosoftManualSteps);