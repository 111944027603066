import React, { Component } from 'react';
import { Formik } from 'formik';
import { cmsGetAdminInvitationRoles, cmsAddAPIKeyPromise, getErrMsg } from '../CallMSAPI.js';
import { toast } from 'react-toastify';
import Select from 'react-select';
import { SubmitButton } from '../FormHelpers';
import { connect } from 'react-redux';
var _ = require('lodash');

class APIKeyForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            apiRolesLoaded: false,
            apiRoles: []
        };

        // Need 'setState' from event handler
        this.getFormDefaults = this.getFormDefaults.bind(this);
    }

    componentDidMount() {
        var self = this;

        cmsGetAdminInvitationRoles(
            self.props.account.Id,
            function (callmsData) {
                self.setState({
                    apiRoles: callmsData.Results,
                    apiRolesLoaded: true
                });
            },
            function (error) {
                toast.error("Roles error: " + error);
            }
        );
    }

    getFormDefaults() {
        var baseDefaults = {
            accountId: this.props.account.Id,
            Name: "",
            RoleIds: []
        };

        return baseDefaults;
    }

    render() {
        var self = this;

        // If we don't have all our API stuff yet, just return a loading notice...
        // That means when we _do_ load Formik, the default values are sensible.
        if (!this.state.apiRolesLoaded) {
            return (
                <div className="api-keyForm">
                    Loading resources...
                </div>
            );
        }

        return (
            <Formik
                // Required so updates from getFormDefaults get propagated down to form
                enableReinitialize={true}
                initialValues={this.getFormDefaults()}
                validate={values => {
                    let errors = {};

                    // Ensure Domain Name exists
                    if (!values.Name) {
                        errors.Name = 'You must enter a name for this API Key.';
                    }

                    return errors;
                }}
                onSubmit={(originalValues, { setSubmitting, setErrors, resetForm }) => {
                    var formik = this;

                    var values = _.cloneDeep(originalValues);

                    values["RoleIds"] = [].slice
                        .call(values["RoleIds"])
                        .map(option => option.Id);

                    // self.props.submitCallback(formik, values, setSubmitting, setErrors, resetForm);
                    cmsAddAPIKeyPromise(values)
                        .then(
                            function (data) {
                                setSubmitting(false);

                                // Keep the form open for now after saves
                                toast.success("API Key successfully created");

                                // reset form
                                resetForm && resetForm(formik.getFormDefaults());

                                // close and trigger reveal of API key form
                                self.props.apiUpdateTrigger && self.props.apiUpdateTrigger(data);
                            },
                            function (error) {
                                setSubmitting(false);
                                toast.error(getErrMsg(error));
                            }
                        );
                }}
            >

                {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                    setFieldValue
                    /* and other goodies */
                }) => {


                    // Make list of roles
                    var roles = null;
                    if (this.state.apiRolesLoaded === true) {
                        roles = (
                            <Select
                                name="RoleIds"
                                options={this.state.apiRoles}
                                className="select-dropdown"
                                // You need to set the new field value
                                onChange={function (opt) {
                                    setFieldValue("RoleIds", opt);
                                }}
                                menuPortalTarget={document.body}
                                menuPosition='fixed'
                                value={values["RoleIds"]}
                                isMulti
                                getOptionLabel={option => option.Name}
                                getOptionValue={option => option.Id}
                            />
                        );
                    }

                    return (
                        <div>
                            <form role="form" className="form update-api-key-form" onSubmit={handleSubmit}>
                                <fieldset disabled={isSubmitting}>
                                    <div className="user-form-parts-wrapper form-padding">
                                        <div className="form-group">
                                            <label className="w-100">Name
                                                <input type="text" className="form-control api-key-input-text" name="Name" value={values.Name} onChange={handleChange} onBlur={handleBlur} />
                                            </label>
                                            <span className="error-message">{errors.Name && touched.Name && errors.Name}</span>
                                        </div>
                                        <div className="form-group">
                                            <label className="w-100">Roles
                                                {roles}
                                            </label>
                                        </div>
                                    </div>
                                    <div className="user-form-action-buttons">
                                        <button className="btn btn-default" onClick={this.props.toggleFormClick}>Cancel</button>
                                        <SubmitButton className={"btn btn-primary pull-right"} isSubmitting={isSubmitting}> Add </SubmitButton>
                                    </div>
                                </fieldset>
                            </form>
                        </div>
                    )
                }
                }

            </Formik>
        );
    }
}
const mapStateToProps = state => {
    const account = state.account;
    return {
        account: account.account,
    };
}
const mapDispatchToProps = (_dispatch) => {
    return {
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(APIKeyForm);