import React, { Component } from 'react';

import NewUserForm from './NewUserForm';
import BulkUserImportForm from './BulkUserImportForm';
import UserReactTable from './UserReactTable';
import {
    cmsGetUnmappedCalls,
    cmsGetCountries
} from '../CallMSAPI.js';
import ActionHeader from '../ActionHeader.js';
import ServiceSync from '../Services/ServiceSync';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import UserLicenceLine from '../LicenceHelpers';
import ProblemCallsModal from './ProblemCallsModal';
import CallsModal from './CallsModal';
import RefreshButton from '../RefreshButton';
import { ActionButton, MessageBar, MessageBarType } from 'office-ui-fabric-react';
import { expandRegistration } from './RegistrationLight';
import { isSystemOwnerSystemAcc, showConsoleLogs } from '../CallMSUIHelpers';

import { connect } from 'react-redux';
import * as actions from '../store/actions/index';
import { idleTimeout } from '../helpers/idleTimeOut';

var _ = require('lodash');

class Users extends Component {

    constructor(props) {
        super(props);

        var locationProps = props.location.props;

        this.state = {
            refreshPaused: [],
            refreshInProgress: false,
            tableRevision: 1,
            countries: [],
            problemCalls: null,
            showProblemCallsModal: false,

            showCallLogModal: false,
            callLogDataUser: null,
            callLogDataService: null,

            showImportForm: false,
            showNewUserForm: false,

            showFlashAlert: locationProps !== undefined && locationProps.showAlert !== undefined
                ? locationProps.showAlert
                : false,

            flashMessageStatus: locationProps !== undefined && locationProps.status !== undefined
                ? locationProps.status
                : "",

            flashMessage: locationProps !== undefined && locationProps.message !== undefined
                ? locationProps.message
                : "",
        }

        this.setRefreshComplete = this.setRefreshComplete.bind(this);
        this.triggerTableUpdate = this.triggerTableUpdate.bind(this);
        this.setRefreshPause = this.setRefreshPause.bind(this);
        this.setRefreshPauseForm = this.setRefreshPauseForm.bind(this);

        this.hideProblemCalls = this.hideProblemCalls.bind(this);
        this.showProblemCalls = this.showProblemCalls.bind(this);

        this.showCallLogModal = this.showCallLogModal.bind(this);
        this.hideCallLogModal = this.hideCallLogModal.bind(this);

        this.toggleImportForm = this.toggleImportForm.bind(this);
        this.hideImportForm = this.hideImportForm.bind(this);

        this.setNewFormOpenStatus = this.setNewFormOpenStatus.bind(this);

        this.showAlert = this.showAlert.bind(this);
        this.anyPBXWithoutSync = this.anyPBXWithoutSync.bind(this);
        this.idleTimeoutUnmount = null;
    }

    toggleImportForm() {
        this.setState(prevState => ({ showImportForm: !prevState.showImportForm }));
    }

    showAlert = (visible) => {
        this.setState({ showFlashAlert: visible });
    }

    hideImportForm() {
        this.setState({ showImportForm: false });
    }

    setNewFormOpenStatus(r) {
        this.setState({ showNewUserForm: r});
    }

    hideProblemCalls() {
        this.setState({ showProblemCallsModal: false });
    }

    showProblemCalls() {
        this.setState({ showProblemCallsModal: true });
    }

    showCallLogModal(service, d) {
        this.setState({
            showCallLogModal: true,
            callLogDataService: service,
            callLogDataUser: d
        });
    }

    hideCallLogModal() {
        this.setState({
            showCallLogModal: false,
            callLogDataService: null,
            callLogDataUser: null
        });
    }

    anyPBXWithoutSync = (services) => {
        if (!services) {
            // shortcut if no services
            return true;
        }
        return services.find(x => x.ServiceCode === 'pbx' && x.SyncEnabled === false) ? true : false
    }

    getFlashAlert = () => {
        return (this.state.showFlashAlert && (  
           <div class={`alert ${this.state.flashMessageStatus === "Success" ? "alert-success" : "alert-warning"} alert-dismissable`}>
               <button type="button" class="close" onClick={() => this.showAlert(false)} data-dismiss="alert" aria-label="Close">
                   <span aria-hidden="true">&times;</span>
               </button>
               {this.state.flashMessage  !== "" && (
                   expandRegistration(this.state.flashMessage )
               )}
               {this.state.flashMessageStatus === "Success" && (  
                   <span>Congratulations, your service is now ready for calling.</span>
                )}
           </div>
        ))
    }

    /* Setup a timer every 5 seconds to look for updates */
    componentDidMount() {
        var self = this;

        this.initIdleTimeout();

        cmsGetCountries(
            self.props.account.Id,
            {},
            function (callmsData) {
                self.setState({ countries: callmsData.Results });
            },
            function (error) {
                toast.error("Countries load error: " + error);
            }
        );

        cmsGetUnmappedCalls(
            self.props.account.Id,
            function (callmsData) {
                // Results array: AccountServiceId, CreatedOn, Identifier, UserPrincipalName, Domain, Reason
                self.setState({ problemCalls: callmsData.Results});
            },
            function (error) {
                toast.error("Unable to load problem calls");
            }
        );

    }

    initIdleTimeout = () => {
        let self = this;
        self.idleTimeoutUnmount = idleTimeout(30, () => {
            self.triggerTableUpdate();
            self.interval = setInterval(() => self.triggerTableUpdate(), 60000);
        }, () => {
            clearInterval(self.interval);
        });
    }

    componentWillUnmount() {
        this.idleTimeoutUnmount();
        clearInterval(this.interval);
    }

    triggerTableUpdate(force) {

        var self = this;
        if ((this.state.refreshInProgress || this.state.refreshPaused.length)  && !force) {
            if(showConsoleLogs()) {
                console.log("Skipping refresh, already in progress (or purposefully paused)");
            }
            return;
        }

        this.setState(prevState => ({
            tableRevision: prevState.tableRevision + 1
        }));

        // Also update top level account
        var updatePromises = [];
      
        updatePromises.push(this.props.refreshAccount());
        

        Promise.all(updatePromises).then(function (data) {
            self.setRefreshComplete();
        })
    }

    // We note the refresh from a callback as the table might decide not to update (e.g. someone is mid-editing)
    setRefreshStarting() {
        this.setState({
            refreshInProgress: true
        })
    }

    setRefreshComplete() {
        this.setState({
            refreshInProgress: false
        })
    }

    setRefreshPauseForm(val) {
        this.setRefreshPause('form', val);
    }

    setRefreshPause(element, val) {
        // state.refreshPaused is an array of pause 'locks', so add/remove a
        // lock by the table due to close/open rows won't have an impact if the
        // new user form is also open.
        if (val) {
            this.setState(prevState => ({
                refreshPaused: _.sortedUniq(prevState.refreshPaused.concat([element]))
            }));
        } else {
            this.setState(prevState => ({
                refreshPaused: _.filter(prevState.refreshPaused, function (v) { return v !== element })
            }));
        }
    }


    render() {
        var self = this;
        const services = self.props.services;

        if (!services.loaded) {
            return <p>Loading service overview...</p>
        }

        var licenceSummary = <UserLicenceLine  />

        if (services.rawList.length === 0) {
            return (
                <div className="Portal">
                    <ActionHeader headerText="Users" subtitle={licenceSummary} />
                    <hr />
                    <div className="alert alert-info">
                        There are no services enabled on your account. 
                        Go to the <Link to="services">services</Link> screen to get started.
                    </div>
                </div>
            );
        }

        var newUserForm = null;
        var bulkImport = null;
 
        if (services.rawList.length && this.state.countries.length) {
            if (   (_.findIndex(services.rawList, function (o) { return o.ServiceCode === 'pbx'; }) !== -1)
                && (_.findIndex(services.rawList, function (o) { return o.ServiceCode !== 'pbx'; }) !== -1)
            ) {
                // Separate if/check to above as the service might have
                // pbx/teams but all sync so doesn't need any buttons shown.
                if (self.anyPBXWithoutSync(services.rawList)) {
                    newUserForm = (
                        <NewUserForm
                            userUpdateTrigger={this.triggerTableUpdate}
                            countries={this.state.countries}
                            setRefreshPause={this.setRefreshPauseForm}
                            otherFormOpen={this.state.showImportForm}
                            setFormOpen={this.setNewFormOpenStatus}
                            submitButtonText={"Add"}
                        >
                            <ActionButton
                                iconProps={{ iconName: 'AddGroup' }}
                                allowDisabledFocus={true}
                                onClick={this.toggleImportForm}
                                disabled={this.state.showNewUserForm}
                            >
                                {this.state.showImportForm ? <strong>Import Users</strong> : <span>Import Users</span>}
                            </ActionButton>
                            <RefreshButton
                                refreshPaused={self.state.refreshPaused}
                                refreshInProgress={self.state.refreshInProgress}
                                triggerRefresh={self.triggerTableUpdate}
                            />
                        </NewUserForm>
                    );

                    bulkImport = (
                        <BulkUserImportForm
                            isVisible={this.state.showImportForm}
                            hideForm={this.hideImportForm}
                        />
                    );
                }
            } else {
                newUserForm = (
                    <div className="alert alert-info">Once you have configured a PBX / Trunk service and a Microsoft service you will be able to add service users.</div>
                );
            }
        }

        var serviceSync = null;
        if (services.rawList.length) {
            serviceSync = (
                <ServiceSync />
            );
        }

        var problemCalls = null;
        if (this.state.problemCalls && this.state.problemCalls.length) {
            var hasTrunk = _.find(services.rawList, function (s) { return s.ServiceVariantCode === 'TRUNK'; });
            var msg = "We have rejected calls from Teams users that you haven't set up in this portal.";
            if (hasTrunk) {
                msg = "We have rejected calls from external callers or Teams users that you haven't set up in this portal.";
            }
            problemCalls = (
                <>
                    <MessageBar messageBarType={MessageBarType.error} isMultiline={true}>
                        <span>{msg} <span className="underline-text clickable" onClick={this.showProblemCalls}>Click here for details.</span></span>
                    </MessageBar>
                    <ProblemCallsModal
                        calls={this.state.problemCalls}
                        isOpen={this.state.showProblemCallsModal}
                        closeCallback={this.hideProblemCalls}
                        services={services.rawList}
                    />
                </>
            );
        }

        var callLogModal = null;
        if (self.state.showCallLogModal) {
            callLogModal = <CallsModal
                isOpen={self.state.showCallLogModal}
                closeCallback={this.hideCallLogModal}
                user={this.state.callLogDataUser}
                service={this.state.callLogDataService}
            />
        }

        return (
            <div className="Portal">
                {
                    this.getFlashAlert()
                }
                <ActionHeader headerText="Users" subtitle={licenceSummary}>{ serviceSync }</ActionHeader>
                {problemCalls}
                <hr />
                {newUserForm}
                {bulkImport}
                {callLogModal}
                <UserReactTable
                    userUpdateTrigger={this.triggerTableUpdate}
                    loadCompleteCallback={() => this.setRefreshComplete()}
                    loadStartingCallback={() => this.setRefreshStarting()}
                    setRefreshPause={(val) => this.setRefreshPause('table', val)}
                    lastUpdate={this.state.tableRevision}
                    countries={this.state.countries}
                    triggerCallLog={this.showCallLogModal}
                />
            </div>
        );
    }
}
const mapStateToProps = state => {
    const services = state.services;
    const account = state.account;
    return {
        account: account.account,
        services
    };
}
const mapDispatchToProps = (dispatch) => {
    return {
        refreshAccount: () => dispatch(actions.refreshAccount)
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Users);
