import React, { Component } from 'react';
import { Field, ErrorMessage } from 'formik';
import { TextField } from 'office-ui-fabric-react/lib/TextField';
import ServiceUserPartAsyncSelect from '../ServiceUserPartAsyncSelect';
import { EditPasswordField } from '../CallMSUIHelpers';
import { CountryHOC, ServiceNumberSelect, PhoneNumberField } from '../Services/ServiceFormHelpers';
import FieldWithDisposition from '../Services/FieldWithDisposition';
import {
    getCallPolicyFormObject,
    getCallPolicyVMFormObject,
    getCallPolicyMOHFormObject
} from '../CallingPolicyHelpers';
import CountryIdAsyncSelect from '../CountryIdAsyncSelect';
import { connect } from 'react-redux';

var _ = require('lodash');

class IdentifierFieldWithRealmSuffix extends Component {

    render() {
        var self = this;

        var suffixOptions = {};
        if (this.props.suffix
        ) {
            suffixOptions = {
                suffix: this.props.suffix.startsWith("@") 
                    ? this.props.suffix
                    : "@".concat(this.props.suffix)
            };
        }

        return (
            <>
                <label className="w-100">SIP Username <span className="required">*</span>
                    <Field component="TextField" className="form-control" name={this.props.name}>
                        {({ field, form }) => {

                            var newValue = field.value;

                            if (suffixOptions.hasOwnProperty('suffix') && newValue) {
                                if (newValue.includes(suffixOptions.suffix)) {
                                    newValue = newValue.replace(suffixOptions.suffix, "");
                                }
                            }

                            var newOnChange = function (e) {
                                var newVal = e.target.value;
                                self.props.setFieldValue(self.props.name, newVal);
                            }
                            return <TextField name={field.name} value={newValue} onChange={newOnChange} disabled={self.props.disabled} {...suffixOptions} />

                        }}
                    </Field>
                </label>
                <ErrorMessage component="span" className="error-message" name={this.props.name} />
            </>
        );
    }
}

class ServiceUserPartForm extends Component {
    constructor(props) {
        super(props);

        var teamsNumberLocked = false;
        var showLock = false;
        var showDirMessage = false;

        // If overwrite enabled, allow toggle but start locked.
        if (this.props.values[this.props.namespace].Identifier !== undefined) {
            teamsNumberLocked = true;
            showLock = true;
        }

        // sync enabled and is externally managed, show the dir message
        if (this.props.service.SyncEnabled === true
            && this.props.values[this.props.namespace].IsExternallyManaged === true
        ) {
            showDirMessage = true;
        }

        this.state = {
            // Default to true if a new part, otherwise false
            showLock: showLock,
            teamsNumberLocked: teamsNumberLocked,
            showDirMessage: showDirMessage
        }

        this.toggleLock = this.toggleLock.bind(this);
    }

    toggleLock(e) {
        e.preventDefault();
        this.setState(prevState => ({ teamsNumberLocked: !prevState.teamsNumberLocked }));
    }

    withNamespace(fieldName) {
        const { namespace } = this.props

        return namespace ? `${namespace}.${fieldName}` : fieldName
    }

    setCountryAndPrefix(setField, CountryName, option, oldOption, optIdentifierName) {
        var self = this;

        if (optIdentifierName && option && option.DialPrefix) {
            var newValue = _.get(self.props.values, optIdentifierName);

            // Remove old dial prefix
            if (newValue && oldOption && newValue.indexOf('+' + oldOption.DialPrefix) === 0) {
                newValue = newValue.replace('+' + oldOption.DialPrefix, '');
            } 

            // Add on new one
            newValue = '+' + option.DialPrefix + newValue;
            setField && setField(optIdentifierName, newValue);
        }

        if (CountryName === 'StateId') {
            setField && setField(this.withNamespace(CountryName), null);
        } else {
            setField && setField(CountryName, option);
        }
    }

    render() {
        // Make the country list
        var countries = null;
        var self = this;

        // Hardwired for now... in the future we may pull this out from the DB
        if (this.props.service.Variant.ServiceCode === 'teams' || this.props.service.Variant.ServiceCode === 'sfb') {
            var countryList = [
                <option key="blank" value="">Select a Country</option>
            ];
            this.props.countries.forEach(function (ele) {
                countryList.push((
                <option key= { ele.Id } value = { ele.Id } > { ele.Name }</option>
                ));
            });

            countries = (
                <div className="form-group">
                    <label>Country</label>
                        <CountryIdAsyncSelect 
                            readOnly={this.props.readonly}
                            countryName={this.withNamespace("CountryId")}
                            countryValue={self.props.values[self.props.namespace].CountryId}
                            onChange={(fieldName, option) => self.setCountryAndPrefix(
                                self.props.setFieldValue,
                                fieldName,
                                option,
                                self.props.values[self.props.namespace].CountryId,
                                (this.props.service.Variant.ServiceCode === 'teams' ? this.withNamespace("Identifier") : null)
                            )}
                        />
                    <ErrorMessage component="span" className="error-message" name={this.withNamespace("CountryId")} />
                </div>
            );
        }

        var domains = null;
        if (this.props.service.Variant.ServiceCode === 'sfb') {
            if (this.props.service.Domains && this.props.service.Domains.length > 0) {
                var domainList = [];
                this.props.service.Domains.forEach(function (d) {
                    domainList.push( <option key={d.Id} value={d.Id}>{d.DomainName}</option> );
                });

                var disabled = this.props.service.Domains.length === 1 ? true : false;

                domains = (
                    <div className="form-group">
                        <label>Domains</label>
                        <Field component="select" disabled={disabled} name={this.withNamespace("DomainId")} className="form-control" readOnly={this.props.readonly}>
                            {domainList}
                        </Field>
                        <ErrorMessage component="span" className="error-message" name={this.withNamespace("DomainId")} />
                    </div>
                );
            }
        }

        // If it's a new user, always allow a password

        // Unused for now
        //var name = null; // Name
        //var email = null; // Email
        var phoneNumber = null; // PhoneNumber
        var externalId = null; // ExternalId

        var identifier = null; // sfb username, teams telephone, pbx username
        var authUsername = null; // AuthUsername, PBX only
        var password = null; // Password, PBX only
        var callingpolicy = null;  // Teams only, Moh/vm etc etc

        if (this.props.service.Variant.ServiceCode === 'teams') {
            identifier = (
                <>
                    <div className="form-group form-group--phone-number">
                        <CountryHOC
                            CountryId={self.props.values[self.props.namespace].CountryId}
                            StateId={self.props.values[self.props.namespace].StateId} 
                            accountId={this.props.account.Id}
                        ><PhoneNumberField
                                name={this.withNamespace("Identifier")}
                                service={self.props.service}
                                setFieldValue={self.props.setFieldValue}
                                showDirMessage={self.state.showDirMessage}
                            />
                        </CountryHOC>
                    </div>
                    {self.props.showExtensionVisibility == null || self.props.showExtensionVisibility == 'Hide'
                        ? null
                        : <div className="form-group">
                            <FieldWithDisposition
                                visibility={self.props.showExtensionVisibility}
                                formObject={{
                                    Type: 'text',
                                    Id: 'Part2.PhoneNumberExtension',
                                    Label: 'Extensions',
                                    Values: self.props.values.Part2.PhoneNumberExtension
                                }}
                                formValues={self.props.values}
                                touched={self.props.touched}
                                errors={[]}
                                setFieldValue={self.props.setFieldValue} />
                            <ErrorMessage component="span" className="error-message" name='Part2.PhoneNumberExtension' />
                        </div>

                    }
                </>
            );

            // Prep variables for calling policy form parts...
            var checkedObj = getCallPolicyFormObject(
                function(setFieldValue, newValue) {
                    if (!newValue || newValue.length === 0 || (newValue.length === 1 && newValue[0] === '')) {
                        self.props.setFieldValue(self.withNamespace('VMCallingPolicy'), '');
                        self.props.setFieldValue(self.withNamespace('MOHCallingPolicy'), '');
                    }
                }
            );
            checkedObj['CheckedLabel'] = 'Override Teams Calling Policy';

            var vmObj = getCallPolicyVMFormObject(self.props.values[self.props.namespace]);
            let vmOptions = [];
            vmObj.Values.forEach(function (v) {
                vmOptions.push(<option key={v.value} value={v.value} disabled={v.hasOwnProperty('disabled') ? v.disabled : false}>{v.label}</option>);
            });

            var mohObj = getCallPolicyMOHFormObject(
                self.props.values[self.props.namespace],
                self.props.serviceNameFromOtherPart
            );
            let mohOptions = [];
            mohObj.Values.forEach(function (v) {
                mohOptions.push(<option key={v.value} value={v.value} disabled={v.hasOwnProperty('disabled') ? v.disabled : false}>{v.label}</option>);
            });

            var cpStatus = self.props.values[self.props.namespace]['DefaultCallingPolicy'];
            cpStatus = cpStatus && cpStatus.includes('custom') ? true : false;

            callingpolicy = (
                <div className="user-form-parts-wrapper">
                    <div className="form-group form-group--full">
                        <div><label>{checkedObj.Label}</label></div>
                        <Field name={self.withNamespace(checkedObj.Id)}>
                            {({ field, form }) => (
                                <label className="no-bold">
                                    <input
                                        type="checkbox"
                                        checked={field.value && field.value.includes(checkedObj.CheckedValue)}
                                        onChange={() => {
                                            var nextValue;
                                            if (field.value && field.value.includes(checkedObj.CheckedValue)) {
                                                nextValue = field.value.filter(
                                                    value => value !== checkedObj.CheckedValue
                                                );
                                                form.setFieldValue(self.withNamespace(checkedObj.Id), nextValue);
                                            } else {
                                                nextValue = field.value.concat(checkedObj.CheckedValue);
                                                form.setFieldValue(self.withNamespace(checkedObj.Id), nextValue);
                                            }

                                            // Add callback capability to set other self.props.values[self.props.namespace] from this one...
                                            if (checkedObj.NewValueCB) {
                                                checkedObj.NewValueCB(self.props.setFieldValue, nextValue);
                                            }
                                        }}
                                    />
                                    &nbsp; {checkedObj.CheckedLabel}
                                </label>
                            )}
                        </Field>
                    </div>
                    {cpStatus ?
                        <div className="form-group">
                            <>
                            Voicemail
                            <Field component="select" name={self.withNamespace(vmObj.Id)} className="form-control">
                                {vmOptions}
                            </Field>
                            <ErrorMessage component="span" className="error-message" name={this.withNamespace(vmObj.Id)} />
                            </>
                        </div>
                        : null}
                    {cpStatus ?
                        <div className="form-group">
                            <>
                            Music On Hold
                        <Field component="select" name={self.withNamespace(mohObj.Id)} className="form-control">
                                {mohOptions}
                            </Field>
                            <ErrorMessage component="span" className="error-message" name={this.withNamespace(mohObj.Id)} />
                            </>
                        </div>
                        : null}
                </div>

            );
        }

        if (this.props.service.Variant.ServiceCode === 'sfb') {
            identifier = (
                <div className="form-group">
                    <label>Username</label>
                    <Field className="form-control" name={this.withNamespace("Identifier")} />
                    <ErrorMessage component="span" className="error-message" name={this.withNamespace("Identifier")} />
                </div>
            );
        }

        if (this.props.service.Variant.ServiceCode === 'sfbfed') {
            identifier = (
                <div className="form-group">
                    <label>Username</label>
                    <Field className="form-control" name={this.withNamespace("Identifier")} />
                    <ErrorMessage component="span" className="error-message" name={this.withNamespace("Identifier")} />
                </div>
            );
        }

        if (this.props.service.Variant.Code === 'PBX') {
            if (this.props.service.SyncEnabled) {
                identifier = (
                    <div className="form-group">
                        <label>Select a PBX User</label>
                        <ServiceUserPartAsyncSelect
                            account={this.props.account}
                            service={this.props.service}
                            name={this.withNamespace("Id")}
                            value={self.props.values[self.props.namespace].Id}
                            onChange={function (option) {
                                self.props.setFieldValue(self.withNamespace("Id"), option.Id);
                                if (option.PhoneNumber) {
                                    if (option.PhoneNumber.toLowerCase().includes("ext")) {
                                        var phoneWithExt = option.PhoneNumber.toLowerCase().split(';ext=')
                                        self.props.setFieldValue(self.withNamespace("PhoneNumber"), phoneWithExt[0]);
                                        self.props.setFieldValue('Part2.PhoneNumberExtension', phoneWithExt[1]);
                                    }
                                    else {
                                        self.props.setFieldValue(self.withNamespace("PhoneNumber"), option.PhoneNumber);
                                    }
                                } else {
                                    self.props.setFieldValue(self.withNamespace("PhoneNumber"), "");
                                }
                            }}
                            key={"pbx-select-key-for-" + this.props.service.Id}
                        />
                        <ErrorMessage component="span" className="error-message" name={this.withNamespace("Id")} />
                    </div>
                );
                phoneNumber = (
                    <div className="form-group">
                        <label className="w-100">Phone Number
                            <Field readOnly={true} className="form-control" name={this.withNamespace("PhoneNumber")} />
                        </label>
                        <ErrorMessage component="span" className="error-message" name={this.withNamespace("PhoneNumber")} />
                    </div>
                );

            } else {
                var suffix = this.props.service && this.props.service.PBXSettings && this.props.service.PBXSettings.Realm ? this.props.service.PBXSettings.Realm : null;
                identifier = (
                    <div className="form-group form-group--identifer">
                        <IdentifierFieldWithRealmSuffix
                            name={this.withNamespace("Identifier")}
                            suffix={suffix}
                            setFieldValue={self.props.setFieldValue}
                            errors={self.props.errors}
                        />
                    </div>
                );
                password = (
                    <div className="form-group">
                        <label for={this.withNamespace("Password")}>Password</label>
                        <EditPasswordField
                            id={this.withNamespace("Password")}
                            hasPassword={self.props.values[this.props.namespace].hasPassword}
                            name={this.withNamespace("Password")}
                            readOnly={this.props.readonly} />
                        <ErrorMessage component="span" className="error-message" name={this.withNamespace("Password")} />
                    </div>
                );
                authUsername = (
                    <div className="form-group">
                        <label>Auth Username</label>
                        <Field className="form-control" name={this.withNamespace("AuthUsername")} readOnly={this.props.readonly} />
                        <ErrorMessage component="span" className="error-message" name={this.withNamespace("AuthUsername")} />
                    </div>
                );
            }
        }

        if (this.props.service.Variant.Code === 'TRUNK') {
            // TODO: Should this be one of the available number range numbers?
            var serviceNumberSelector = null;
            if (   this.props.service.PBXSettings
                && this.props.service.PBXSettings.FromHeaderType
                // Guard against unknown enum types
                && typeof this.props.service.PBXSettings.FromHeaderType === 'string'
                // 'CallerId' can be single string or a prefix to extended types
                && this.props.service.PBXSettings.FromHeaderType.indexOf('CallerId') === 0
            ) {
                serviceNumberSelector = (
                    <div className="form-group">
                        <label>Select a Caller ID:</label>
                        <ServiceNumberSelect
                            service={self.props.service}
                            name={this.withNamespace("AccountServiceTrunkServiceNumberId")}
                            value={self.props.values[self.props.namespace]['AccountServiceTrunkServiceNumberId'] ? self.props.values[self.props.namespace]['AccountServiceTrunkServiceNumberId'] : (self.props.values[self.props.namespace]['ServiceNumberBehaviourTypeId'] ? self.props.values[self.props.namespace]['ServiceNumberBehaviourTypeId'] : null)}
                            onChange={function (option) {
                                if (option.DisplayName != 'Anonymous') {
                                    self.props.setFieldValue(self.props.namespace + '.AccountServiceTrunkServiceNumberId', option.Id);
                                    self.props.setFieldValue(self.props.namespace + '.ServiceNumberBehaviourTypeId', null);
                                } else if (option.DisplayName === 'Anonymous') {
                                    self.props.setFieldValue(self.props.namespace + '.ServiceNumberBehaviourTypeId', "JoMQ49NK");
                                    self.props.setFieldValue(self.props.namespace + '.AccountServiceTrunkServiceNumberId', null);
                                }
                            }}
                            key={"trunk-select-key-for-" + this.props.service.Id}
                        />
                        <ErrorMessage component="span" className="error-message" name={this.withNamespace("Id")} />
                    </div>
                );
            }

            identifier = (
                <>
                    <div className="form-group">
                        <label>Select a Trunk Number</label>
                        <ServiceUserPartAsyncSelect
                            showNumberWithName={true}
                            account={this.props.account}
                            service={this.props.service}
                            name={this.withNamespace("Id")}
                            value={self.props.values[self.props.namespace].Id}
                            onChange={function (option) {
                                self.props.setFieldValue(self.withNamespace("Id"), option.Id)
                                self.props.setFieldValue(self.withNamespace("Identifier"), option.Identifier)
                            }}
                            key={"trunk-select-key-for-" + this.props.service.Id}
                        />
                        <ErrorMessage component="span" className="error-message" name={this.withNamespace("Id")} />
                    </div>

                    {serviceNumberSelector}

                </>
            );
        }


        // If SyncEnabled, we only care about linking up existing entries...
        // Currently just for Teams
        if (this.props.service.SyncEnabled && this.props.service.Variant.ServiceCode === 'teams')
        {
            var lockedEdit = null;
            var lockIcon = (self.state.teamsNumberLocked ? <i className="fa-solid fa-lock"></i> : <i className="fa-solid fa-unlock"></i>);
            if (self.state.showLock) {
                lockedEdit = <button className="btn btn-link btn-xs" onClick={(e) => this.toggleLock(e)}>{lockIcon}</button>;
            }

            var phoneNumberSyncField = (
                <>
                    <div className="form-group form-group--phone-number">
                        <CountryHOC
                            CountryId={self.props.values[self.props.namespace].CountryId}
                            StateId={self.props.values[self.props.namespace].StateId}  
                            accountId={this.props.account.Id}
                        ><PhoneNumberField
                                name={this.withNamespace("PhoneNumber")}
                                service={self.props.service}
                                setFieldValue={self.props.setFieldValue}
                                lockedEdit={lockedEdit}
                                disabled={self.state.teamsNumberLocked}
                                showDirMessage={self.state.showDirMessage}
                            />
                        </CountryHOC>
                    </div>
                    {self.props.showExtensionVisibility == null || self.props.showExtensionVisibility == 'Hide'
                        ? null
                        : <div className="form-group">
                            <FieldWithDisposition
                                visibility={self.props.showExtensionVisibility}
                                formObject={{
                                    Type: 'text',
                                    Id: 'Part2.PhoneNumberExtension',
                                    Label: 'Extensions',
                                    Values: self.props.values.Part2.PhoneNumberExtension
                                }}
                                formValues={self.props.values}
                                touched={self.props.touched}
                                errors={[]}
                                setFieldValue={self.props.setFieldValue} />
                                <ErrorMessage component="span" className="error-message" name='Part2.PhoneNumberExtension' />
                            </div>
                           
                    }
                </>
            );

            if (self.props.numberFromOtherPart) {
                phoneNumberSyncField = null;
            }

            // Note, there is no prefix support yet for the new numbers added here.
            return (
                <div className="user-form-parts-wrapper">
                    <div className="form-group">
                         <label className="w-100">Select a User
                            <ServiceUserPartAsyncSelect
                                showEmailWithName={true}
                                account={this.props.account}
                                service={this.props.service}
                                name={this.withNamespace("SyncUserPart")}
                                value={self.props.values[self.props.namespace].SyncUserPart}
                                key={"teams-select-key-for-" + this.props.service.Id}
                                onChange={function (option) {
                                    self.props.setFieldValue(self.withNamespace("SyncUserPart"), option)

                                    if (option.CountryId) {
                                        self.props.setFieldValue(self.withNamespace("CountryId"), option.CountryId);
                                    }

                                    if (option.PhoneNumber) {
                                        if (option.PhoneNumber.toLowerCase().includes("ext")) {
                                            var phoneWithExt = option.PhoneNumber.toLowerCase().split(';ext=')
                                            self.props.setFieldValue(self.withNamespace("PhoneNumber"), phoneWithExt[0]);
                                            self.props.setFieldValue('Part2.PhoneNumberExtension', phoneWithExt[1]);
                                        }
                                        else {
                                            self.props.setFieldValue(self.withNamespace("PhoneNumber"), option.PhoneNumber);
                                        }
                                    } else {
                                        self.props.setFieldValue(self.withNamespace("PhoneNumber"), "");
                                    }

                                    // Teams sync specific case. If we have
                                    // IsExternallyManaged flag set and sync
                                    // enabled then we want to show the dir
                                    // sync message.
                                    var showDirMessage = false;
                                    if (option.IsExternallyManaged
                                        && self.props.service.SyncEnabled
                                    ) {
                                        showDirMessage = true;
                                    }

                                    self.setState({
                                        teamsNumberLocked: (option.PhoneNumber ? true : false),
                                        showLock: (option.PhoneNumber ? true : false),
                                        showDirMessage: showDirMessage
                                    })
                                } }
                            />
                        </label>
                        <ErrorMessage component="span" className="error-message" name={this.withNamespace("SyncUserPart")} />  
                    </div>
                    {phoneNumberSyncField}
                    {callingpolicy}
                </div>
            );
        }


        // We customise the order slightly for different services...
        if (this.props.service.Variant.ServiceCode === 'teams') {
            return (
                <div className="user-form-parts-wrapper">
                    {countries}
                    {identifier}
                    {callingpolicy}
                </div>
            );
        } else if (this.props.service.Variant.ServiceCode === 'sfb') {
            return (
                <div className="user-form-parts-wrapper">
                    {identifier}
                    {domains}
                    {countries}
                    {phoneNumber}
                    {externalId}
                    {authUsername}
                    {password}
                </div>
            );
        } else {
            // PBX and friends...
            return (
                <div className="user-form-parts-wrapper user-form-parts-wrapper--pbx">
                    {countries}
                    {identifier}
                    {domains}
                    {phoneNumber}
                    {externalId}
                    {authUsername}
                    {password}
                </div>
            );
        }
    }
}
const mapStateToProps = state => {
    const account = state.account;
    return {
        account: account.account
    };
}
const mapDispatchToProps = (_dispatch) => {
    return {
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(ServiceUserPartForm);
