import React, { Component } from 'react';
import { Formik } from 'formik';
import { cmsGetAdminInvitationRoles, cmsAddAdminInvite } from '../CallMSAPI.js';
import { toast } from 'react-toastify';
import Select from 'react-select';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { SubmitButton } from '../FormHelpers';
import { Dialog, DialogType } from 'office-ui-fabric-react/lib/Dialog';
import { Icon } from 'office-ui-fabric-react/lib/Icon';
import { connect } from 'react-redux';
var _ = require('lodash');


class AdminInviteForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            apiRolesLoaded: false,
            apiRoles: [],
            inviteLink: null
        };

        // Need 'setState' from event handler
        this.getFormDefaults = this.getFormDefaults.bind(this);
        this.justCopiedAction = this.justCopiedAction.bind(this);
    }

    componentDidMount() {
        var self = this;

        cmsGetAdminInvitationRoles(
            self.props.account.Id,
            function (callmsData) {
                self.setState({
                    apiRoles: callmsData.Results,
                    apiRolesLoaded: true
                });
            },
            function (error) {
                toast.error("Roles error: " + error);
            }
        );
    }

    justCopiedAction() {
        toast.success("Invite link copied to clipboard");
        this.setState({ inviteLink: null });
    }

    getFormDefaults() {
        var baseDefaults = {
            accountId: this.props.account.Id,
            Email: "",
            FirstName: "",
            LastName: "",
            RoleIds: []
        };

        return baseDefaults;
    }

    render() {
        var self = this;

        // If we don't have all our API stuff yet, just return a loading notice...
        // That means when we _do_ load Formik, the default values are sensible.
        if (!this.state.apiRolesLoaded) {
            return (
                <div className="admin-inviteForm">
                    Loading resources...
                </div>
            );
        }

        return (
            <Formik
                // Required so updates from getFormDefaults get propagated down to form
                enableReinitialize={true}
                initialValues={this.getFormDefaults()}
                validate={values => {
                    let errors = {};

                    return errors;
                }}
                onSubmit={(originalValues, { setSubmitting, setErrors, resetForm }) => {
                    var formik = this;

                    var values = _.cloneDeep(originalValues);

                    values["RoleIds"] = [].slice
                        .call(values["RoleIds"])
                        .map(option => option.Id);

                    if (values["FirstName"] === "") {
                        delete values["FirstName"];
                    }
                    if (values["LastName"] === "") {
                        delete values["LastName"];
                    }

                    // self.props.submitCallback(formik, values, setSubmitting, setErrors, resetForm);
                    cmsAddAdminInvite(values, function (data) {
                        setSubmitting(false);

                        if (data.URI) {
                            self.setState({ inviteLink: data.URI });
                        }
                        else {
                            // Keep the form open for now after saves
                            // self.props.closeForm();
                            toast.success("Admin invitation successfully created");
                        }

                        // reset form
                        resetForm && resetForm(formik.getFormDefaults());

                        self.props.userUpdateTrigger && self.props.userUpdateTrigger();

                    }, function (error) {
                        setSubmitting(false);
                        toast.error(error);
                    })
                }}
            >

                {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                    setFieldValue
                    /* and other goodies */
                }) => {


                    // Make list of roles
                    var roles = null;
                    if (this.state.apiRolesLoaded === true) {
                        roles = (
                            <Select
                                name="RoleIds"
                                options={this.state.apiRoles}
                                className="select-dropdown"
                                // You need to set the new field value
                                onChange={function (opt) {
                                    setFieldValue("RoleIds", opt);
                                }}
                                value={values["RoleIds"]}
                                isMulti
                                getOptionLabel={option => option.Name}
                                getOptionValue={option => option.Id}
                            />
                        );
                    }

                    var inviteLinkReveal = null;
                    if (this.state.inviteLink) {
                        inviteLinkReveal = (
                            <Dialog
                                hidden={!this.state.inviteLink}
                                onDismiss={() => this.setState({ inviteLink: null })}
                                isBlocking={true}
                                dialogContentProps={{
                                    type: DialogType.normal,
                                }}
                                modalProps={{
                                    styles: { main: { maxWidth: 450 } },
                                }}
                            >
                                <div>
                                    <div style={{ textAlign: "center", fontSize: "xxx-large" }}>
                                        <Icon iconName="CheckMark" style={{ color: "#6bb700" }} />
                                    </div>
                                    <br />
                                    <p style={{ textAlign: "center" }}>Invitation successfully created.</p>
                                    <p style={{ textAlign: "center" }}>Copy your link below.</p>
                                    <br />
                                    <div>
                                        <fieldset style={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center'
                                        }}>
                                            <input type="text" value={this.state.inviteLink} style={{ padding: "5px 12px", borderColor: 'rgb(16, 110, 190)' }}></input>
                                            <CopyToClipboard text={this.state.inviteLink} onCopy={this.justCopiedAction}>
                                                <button className="btn btn-primary">Copy</button>
                                            </CopyToClipboard>
                                        </fieldset>
                                    </div>
                                </div>
                            </Dialog>
                        );
                    }

                    return (
                        <>
                            <div className="w-100">
                                <form role="form" className="form w-100" onSubmit={handleSubmit}>
                                    <fieldset className="user-form-parts-wrapper w-100 form-padding" disabled={isSubmitting}>
                                        <div className="form-group" style={{padding:'0 15px'}}>
                                            <label className="w-100">Email
                                                <input type="email" className="form-control admin-invite-input-text" name="Email" value={values.Email} onChange={handleChange} onBlur={handleBlur} />
                                            </label>
                                            <span className="error-message">{errors.Email && touched.Email && errors.Email}</span>
                                        </div>
                                        <div className="form-group" style={{ padding: '0 15px' }}>
                                            <label className="w-100">First Name
                                                <input type="text" className="form-control admin-invite-input-text" name="FirstName" value={values.FirstName} onChange={handleChange} onBlur={handleBlur} />
                                            </label>
                                            <span className="error-message">{errors.FirstName && touched.FirstName && errors.FirstName}</span>
                                        </div>
                                        <div className="form-group" style={{ padding: '0 15px' }}>
                                            <label className="w-100">Last Name
                                                <input type="text" className="form-control admin-invite-input-text" name="LastName" value={values.LastName} onChange={handleChange} onBlur={handleBlur} />
                                            </label>
                                            <span className="error-message">{errors.LastName && touched.LastName && errors.LastName}</span>
                                        </div>
                                        <div className="form-group" style={{ padding: '0 15px' }}>
                                            <label className="w-100">Roles
                                                {roles}
                                            </label>
                                        </div>

                                        <div className="user-form-action-buttons w-100">
                                            <button className="btn btn-default"
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    self.setState({
                                                        inviteLink: null
                                                    }, function (e) {
                                                        self.props.toggleFormClick();
                                                    });
                                                }}>Cancel</button>
                                            <SubmitButton className={"btn btn-primary pull-right"}
                                                isSubmitting={isSubmitting}
                                                onClick={(e) => {
                                                    if (!values.Email || !/@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.Email)) {
                                                        e.preventDefault();
                                                        errors.Email = 'You must enter a valid email address.';
                                                        toast.error('You must enter a valid email address.');
                                                    }
                                                }}>
                                                <i className="fa-solid fa-envelope" style={{ marginRight: '5px' }}></i>
                                                Send via Email
                                            </SubmitButton>
                                            <SubmitButton className={"btn btn-primary pull-right"}
                                                isSubmitting={isSubmitting}
                                                style={{ marginRight: '10px' }}
                                                onClick={(e) => {
                                                    if (self.state.inviteLink) {
                                                        self.setState({ inviteLink: null });
                                                    }
                                                    values.Email = "";
                                                }}>
                                                <i className="fa-solid fa-link" style={{ marginRight: '5px' }}></i>
                                                Share via link
                                            </SubmitButton>
                                        </div>
                                    </fieldset>
                                </form>
                            </div>
                            {inviteLinkReveal}
                        </>
                    )
                }
                }

            </Formik>
        );
    }
}
const mapStateToProps = state => {
    const account = state.account;
    return {
        account: account.account
    };
}
const mapDispatchToProps = (_dispatch) => {
    return {

    }
}
export default connect(mapStateToProps, mapDispatchToProps)(AdminInviteForm);
