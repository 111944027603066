import React, { Component } from 'react';
import {
    cmsGetSubscriptionOffers,
    cmsAddAccount,
    cmsPostPromise,
    cmsAddAdminInvite,
    extractNewSubscriptionData,
    getBaseOrderInfo,
    cmsGetPromise,
    getErrMsg
} from '../CallMSAPI.js';
import { toast } from 'react-toastify';
import NewAccountFormInner from './NewAccountFormInner';
import { showConsoleLogs } from '../CallMSUIHelpers.js';
import { connect } from 'react-redux';
import * as actions from '../store/actions/index';

var _ = require('lodash');

class NewAccountForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            // Visual state
            hideForm: true,
            offers: [],
            accountOwnerRoleId: 1, 
            brands: null
        };

        // Need 'setState' from event handler
        this.toggleForm = this.toggleForm.bind(this);
        this.saveNewAccount = this.saveNewAccount.bind(this);
    }

   componentDidMount() {
       this.getOffers();
       if (this.props.hasRole('AccountAddBranding')) {
           this.getBrands();
       }
    }

    toggleForm(e) {
        this.setState(prevState => ({
            hideForm: !prevState.hideForm
        }));

        if (e !== undefined) {
            e.preventDefault();
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.contractAccount.Id !== this.props.contractAccount.Id) {
            this.getOffers();
        }
    }

    getOffers() {
        var self = this;
        cmsGetSubscriptionOffers(
            self.props.contractAccount.Id,
            function (offers) {
                self.setState({
                    offers: offers.Results
                });
            }, function (error) {
                if(showConsoleLogs()) {
                    console.log("Unable to retrieve any account offers");
                }
                self.setState({
                    offers: []
                });
            }
        );
    }

    getBrands = () => {
        var self = this;

        cmsGetPromise({
            objectType: "system/branding/brands",
        }).then(response => {
            self.setState({
                brands: response.data.Brands
            });
        })
    }

    saveNewAccount(formik, values, setSubmitting, setErrors, resetForm) {
        var self = this;

        var inviteEmail = values['InviteEmail'];
        if (values.hasOwnProperty('InviteEmail')) {
            delete values['InviteEmail'];
        }

        var orderRef = values['OrderReference'];
        if (values.hasOwnProperty('OrderReference')) {
            delete values['OrderReference'];
        }

        var orderParts = extractNewSubscriptionData(_.map(self.state.offers, function (o) { if (o) { return o.Id } }), values);

        cmsAddAccount(values, function(data) {
                setSubmitting(false);

                if (inviteEmail && self.state.accountOwnerRoleId !== null) {
                    // TODO: Complete this.
                    var newInvite = {
                        'accountId': data.Id,
                        'Email': inviteEmail,
                        'RoleIds': [
                            self.state.accountOwnerRoleId
                        ],
                    };

                    cmsAddAdminInvite(newInvite, function (data) {
                        toast.success("Account owner invitation sent to " + inviteEmail);
                    }, function (error) {
                        toast.error("Unable to send account owner invite: " + getErrMsg(error));
                    });
                }

                if (orderParts && orderParts.length) {
                    var baseOrder = getBaseOrderInfo(self.props.contractAccount.Id, data.Id, orderRef);
                    baseOrder["NewItems"] = orderParts;

                    cmsPostPromise(baseOrder).then(function (ok) {
                        toast.success("New subscriptions added to account");
                    }, function (err) {
                        toast.error("Unable to order subscriptions: " + getErrMsg(err));
                    });
                }

                // Fire further up chain updates (e.g. table redraw)
                self.props.accountUpdateTrigger && self.props.accountUpdateTrigger(data);

                // Clear form, close form
                resetForm && resetForm(formik.getFormDefaults());
                self.toggleForm();
                toast.success("New Account successfully created");

            }, function(error) {
                setSubmitting(false);
                toast.error(error);
            }
        );

    }

    render() {
        var self = this;

        var disableAddButton = false;
        var formInner = null;
        if (!this.state.hideForm) {
            disableAddButton = true
            formInner = (
                <div className="alert alert-info domain-form-wrapper">
                    <NewAccountFormInner 
                        offers={self.state.offers}
                        showInvitePart={this.state.accountOwnerRoleId !== null}
                        brands={this.state.brands}
                        closeForm={function (e) {
                            self.toggleForm(e);
                        }}
                        submitCallback={function (f, v, s1, s2, r1) {
                            self.saveNewAccount(f, v, s1, s2, r1);
                        }}
                    />
                </div>
            );
        }

        return (
            <div className="domainForm">
                <div className="user-form-action-buttons user-form-action-buttons--top">
                    <button className="btn btn-primary" onClick={this.toggleForm} disabled={disableAddButton} >
                        <i className="fa-solid fa-plus"></i> Add Account
                    </button>
                    {this.props.children}
                </div>
                {formInner}
            </div>
        );
    }
}
const mapStateToProps = state => {
    const account = state.account;
    return {
        account: account.account,
        baseAccount: account.baseAccount,
        baseAccountInfo: account.baseAccountInfo,
        contractAccount: account.contractAccount
    };
}
const mapDispatchToProps = (dispatch) => {
    return {
        hasRole: (uiPart = '') => dispatch(actions.hasRole(uiPart))
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(NewAccountForm);
