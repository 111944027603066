import React, { Component } from 'react';
import {
    cmsGetPromise,
    getErrMsg
} from '../../CallMSAPI.js';
import { toast } from 'react-toastify';
import ExpandingTable from '../../ExpandingReactTable';
import { connect } from 'react-redux';

import dayjs from '../../dayjs.js';
var _ = require('lodash');

class BillingAuditTable extends Component {
    constructor(props) {
        super(props);
        this.state = {

            // Used by table
            filters: [],
            searchText: []

        };

        this.getColumns = this.getColumns.bind(this);
        this.fetchData = this.fetchData.bind(this);
        this.accountFilter = this.accountFilter.bind(this);
    }

    tableFilter(filter, onChange, type) {
        var self = this;
        var opts = null;

        return (
            <input
                onChange={event => {
                    let temp =[];
                    temp[type] = event.currentTarget.value;
                    self.setState({filters:  temp});
                }}
                style={{ width: "100%" }}
                value={self.state.filters[type]}
            />
        );
    }

    fetchData(filterValues, searchText, pageSize, page, sortSettings) {
        var self = this;

        var apiParams = {
            accountId: this.props.contractAccount.Id,
            objectType: 'orders',
        };

        if (apiParams.accountId === undefined) {
            return;
        }

        if (pageSize) {
            apiParams['pageSize'] = pageSize;
        }

        if (page) {
            apiParams['currentPage'] = page;
        }

        if(sortSettings) {
            if(sortSettings.Column === 'Order Date'){
                apiParams['SortBy'] = 'OrderDate';
            }
            if(sortSettings.Column === 'Target Account'){
                apiParams['SortBy'] = 'TargetAccountName';
            }
            if(sortSettings.Column === 'User'){
                apiParams['SortBy'] = 'UserPrincipalName';
            }
            apiParams['SortDirection'] = sortSettings.SortDirection;
        }

        if(filterValues){
            if(filterValues['Target Account']) {
                apiParams['TargetAccountId'] = filterValues['Target Account'];
            }
            if(filterValues['Order Reference']) {
                apiParams['OrderReference'] = filterValues['Order Reference'];
            }
        }
        return cmsGetPromise(apiParams);

    }
    
    getColumns() {
        var self = this;
        var columns = [];

        columns.push(
            {
                id: "orderdate",
                Header: 'Order Date',
                accessor: function (order) {
                    var t = dayjs.utc(order.OrderDate);
                    return (
                        <>
                            <span>{t.format("D MMMM YYYY")}</span>
                        </>
                    );
                },
                sortable: true,
                filterable: false
            }
        );

        columns.push(
            {
                id: "targetaccountname",
                Header: 'Target Account',
                accessor: function (order) {
                    return order.TargetAccountName
                },
                sortable: true,
                filterMethod: (filter, row) => {
                    if (filter.value === "all") {
                        return true;
                    }
                    if (filter.value === "true") {
                        return true;
                    }
                    return false;
                },
                Filter: ({ filter, onChange }) => self.accountFilter(
                    self.props.allAccounts,
                    filter,
                    onChange,
                    'Target Account'
                )
            }
        );

        columns.push(
            {
                id: "userprincipalname",
                Header: 'User',
                accessor: function (order) {
                    return order.UserPrincipalName
                },
                sortable: true,
                filterable: false
            }
        );

        columns.push(
            {
                id: "reference",
                Header: 'Order Reference',
                accessor: function (order) {
                    return order.OrderReference
                },
                sortable: false,
                filterable: true,
                Filter: ({ filter, onChange }) => self.tableFilter(filter, onChange, "Order Reference")
            }
        );


        columns.push(
            {
                id: "itemcount",
                Header: 'Items',
                accessor: function (order) {
                    return order.Items.length
                },
                sortable: false,
                filterable: false
            }
        );

        return columns;
    }

    render() {
        var self = this;

        return (
            <ExpandingTable
                loadingText={"Loading Data..."}
                filters={self.state.filters}
                searchText={self.state.searchText}
                noDataText={"No billing data available."}
                showPagination={true}
                fetchData={self.fetchData}
                sortBy={
                    {
                        Column: "started",
                        SortDirection: 'Descending'
                    }
                }
                columns={self.getColumns()}
                SubComponent={row => {
                    var { loadCompleteCallback, loadStartingCallback, setRefreshPause, closeForm, services, ...otherProps } = { ...self.props };

                    var data = row.original;

                    var rows = [];
                    _.forEach(data.Items, function (i) {
                        var cols = [];
                        cols.push(<td key="type">{i.Type}</td>);
                        cols.push(<td key="subscription_id">{i.SubscriptionId}</td>);

                        var qChange = (i.PreviousQuantity && i.PreviousQuantity !== i.NewQuantity ? true : false);
                        cols.push(
                            <td key="quantity" className={qChange ? "has-change" : ""}>
                                {i.PreviousQuantity && i.PreviousQuantity !== i.NewQuantity ? <span className="old-value"> {i.PreviousQuantity} <i className="fa-solid fa-arrow-right"></i> </span> : null}
                                {i.NewQuantity}
                            </td>
                        );

                        var sChange = (i.PreviousState && i.PreviousState !== i.NewState ? true : false);
                        cols.push(
                            <td key="state" className={sChange ? "has-change" : ""}>
                                {i.PreviousState && i.PreviousState !== i.NewState ? <span className="old-value"> {i.PreviousState} <i className="fa-solid fa-arrow-right"></i> </span> : null}
                                {i.NewState}
                            </td>
                        );

                        var cChange = (i.PreviousOfferCode && i.PreviousOfferCode !== i.NewOfferCode ? true : false);
                        cols.push(
                            <td key="offer" className={cChange ? "has-change" : ""}>
                                {i.PreviousOfferCode && i.PreviousOfferCode !== i.NewOfferCode ? <span className="old-value"> {i.PreviousOfferCode} <i className="fa-solid fa-arrow-right"></i> </span> : null}
                                {i.NewOfferCode}
                            </td>
                        );

                        var pChange = false;
                        if ((i.PreviousCurrencyCode && i.PreviousCurrencyCode !== i.NewCurrencyCode)
                            || (i.PreviousUnitPrice && i.PreviousUnitPrice !== i.NewUnitPrice)
                        ) {
                            pChange = true;
                        }

                        cols.push(
                            <td key="price" className={pChange ? "has-change" : ""}>
                                {i.PreviousCurrencyCode && i.PreviousCurrencyCode !== i.NewCurrencyCode ? <span className="old-value"> {i.PreviousCurrencyCode} <i className="fa-solid fa-arrow-right"></i> </span> : null}
                                {i.NewCurrencyCode}
                                &nbsp;
                                {i.PreviousUnitPrice && i.PreviousUnitPrice !== i.NewUnitPrice ? <span className="old-value"> {i.PreviousUnitPrice.toFixed(2)} <i className="fa-solid fa-arrow-right"></i> </span> : null}
                                {i.NewUnitPrice.toFixed(2)}
                            </td>
                        );

                        rows.push(
                            <tr className={"audit-order-item-row order-item-" + i.Type}>
                                {cols}
                            </tr>
                        )
                    });

                    var t = dayjs.utc(data.OrderDate);
                    return (
                        <div className="order-item-wrapper">
                            <p><strong>Order Details: {data.OrderReference ? data.OrderReference : null}</strong></p>
                            <ul>
                                <li><span>{t.format("MMMM Do YYYY, h:mm:ss a")} (UTC)</span></li>
                                <li><span>Target Account:</span> {data.TargetAccountName}</li>
                                <li><span>Owner Account:</span> {data.OwnerAccountName}</li>
                                <li><span>User (UPN):</span> {data.UserPrincipalName}</li>
                                <li><span>User Name:</span> {data.UserName}</li>
                                {data.ExternalUserId ? <li><span>External User Id:</span> {data.ExternalUserId}</li> : null}
                                <li><span>IP Address:</span> {data.IPAddress}</li>
                                <li><span>User Agent:</span> {data.UserAgent}</li>
                            </ul>
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th>Type</th>
                                        <th>Subscription Id</th>
                                        <th>Quantity</th>
                                        <th>State</th>
                                        <th>Offer Code</th>
                                        <th>Unit Price</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {rows}
                                </tbody>
                            </table>

                        </div>
                    );
                }}
            />
        );
    }

    accountFilter(allAccounts, filter, onChange, type) {
        let self = this;
        var opts = _.map(
            _.orderBy(allAccounts, 'Name', 'asc'),
            function (s) {
                return (<option key={s.Id} value={s.Id}>{s.Name}</option>)
            }
        );

        return (
            <select
                onChange={event => {
                    let temp = [...this.state.filters];
                    temp[type] = event.target.value;
                    self.setState({filters: temp})
                }}
                style={{ width: "100%" }}
                value={self.state.filters && self.state.filters[type] ? self.state.filters[type] : "all"}
            >
                <option key='default' value=''>All</option>
                {opts}
            </select>
        );
    }

}
const mapStateToProps = state => {
    const account = state.account;
    return {
        contractAccount: account.contractAccount
    };
}
const mapDispatchToProps = (_dispatch) => {
    return {
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(BillingAuditTable);
