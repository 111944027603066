import React, { Component } from 'react';
import { Formik } from 'formik';
import PlaceOrder from './PlaceOrder';
import OrderFormTable from './OrderFormTable';
import { extractNewSubscriptionData } from '../CallMSAPI.js';
import { isTrialCode } from '../LicenceHelpers';
import { SubmitButton } from '../FormHelpers';
import { showConsoleLogs } from '../CallMSUIHelpers.js';
import { connect } from 'react-redux';
import * as actions from '../store/actions/index';

var _ = require('lodash');

class NewOrderForm extends Component {

    constructor(props) {
        super(props);

        // Add offers that could be placed as part of a _new_ order
        var okOffers = [];
        props.offers.forEach(function (entry) {
            // Filter out trial user offers if already has subscriptions
            if (props.allSubscriptions && props.allSubscriptions.length > 0 && isTrialCode(entry.Code)) {
                return;
            }

            // Get offers that are in an orderable state
            if (entry.IsAvailable) {
                okOffers.push(entry);
            }
        });

        // Filter out this existing, i.e. where the quantity should be changed
        // of an existing subscription rather than a new order placed
        props.subscriptions.forEach(function (sub) {
            var res = _.remove(okOffers, { 'Id': sub.OfferId });
            if(showConsoleLogs()) {
                console.log("Removed " + res.length + " 'new' offers with ID: " + sub.OfferId);
            }
        });


        this.state = {
            okOffers: okOffers,
            openModal: false
        };

        this.showModal = this.showModal.bind(this);
        this.hideModal = this.hideModal.bind(this);
    }

    showModal() {
        this.setState({
            openModal: true
        })
    }

    hideModal(entry) {
        this.setState({
            openModal: false
        })
    }

    getFormDefaults() {
        var baseDefaults = {};

        this.state.okOffers.forEach(function (o) {
            baseDefaults["quantity-" + o.Id] = 0;
        });

        return baseDefaults;
    }

    render() {
        var self = this;

        if (!self.props.hasRole('PlaceOrders')) {
            return null;
        }

        // No offers to place order against
        if (self.state.okOffers.length === 0) {
            return null;
        }

        return (
            <Formik
                // Required so updates from getFormDefaults get propagated down to form
                enableReinitialize={true}
                initialValues={this.getFormDefaults()}
                validate={values => {
                    let errors = {};

                    return errors;
                }}
                onSubmit={(originalValues, { setSubmitting, setErrors, resetForm }) => {
                    var formik = this;

                    // We make various mods to the data before submission, we
                    // don't want to fiddle the underlying data though.
                    var values = _.cloneDeep(originalValues);

                    // Add changes in submit friendly way...
                    values['changes'] = extractNewSubscriptionData(_.map(self.state.okOffers, function (o) { if (o) { return o.Id } }), values);

                    self.hideModal();
                    self.props.submitCallback(formik, values, setSubmitting, setErrors, resetForm);
                }}
            >

                {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                    setFieldValue,
                    setSubmitting,
                    setErrors,
                    resetForm
                    /* and other goodies */
                }) => {
                    var temp = _.cloneDeep(self.state.okOffers);
                    var canOrder = false;
                    temp.forEach(function (t) {
                        if (t) {
                            t['Quantity'] = values["quantity-" + t.Id];
                            if (t.Quantity > 0) {
                                canOrder = true;
                            }
                        }
                    });

                    var newOrderModal = (
                        // Clone OKoffers
                        // Add in Quantity field
                        <PlaceOrder
                            {...self.props}
                            isOpen={self.state.openModal}
                            subs={temp}
                            orderCallback={(reference) => {
                                values['OrderReference'] = reference;
                                handleSubmit(
                                    values,
                                    setSubmitting,
                                    setErrors,
                                    resetForm
                                )
                            }}
                            closeCallback={self.hideModal}
                        />
                    );

                    if (self.state.okOffers.length === 0) {
                        // <p>There are currently no offers available for your account</p>;
                        return null;
                    }

                    return (
                        <div className="new-order-wrapper" title="Purchase Services">
                            {newOrderModal}
                            <h4>Purchase Services</h4>
                            <form role="form" className="form user-form" onSubmit={
                                (e) => { e.preventDefault(); self.showModal() }
                            }>
                                <fieldset disabled={isSubmitting}>
                                    <OrderFormTable
                                        values={values}
                                        okOffers={self.state.okOffers}
                                        setFieldValue={setFieldValue}
                                        limitSubscriptions={self.props.limitSubscriptions}
                                    />

                                    <div className="user-form-action-buttons">
                                        <SubmitButton className={"btn btn-primary mb-2 right-action-button"} isSubmitting={isSubmitting} forceDisable={!canOrder}> {this.submitButtonText()} </SubmitButton>
                                        <button type='button' className="btn right-action-button mb-2 close-form" onClick={this.props.closeCallback}>{this.closeButtonText()}</button>
                                    </div>

                                    {/* <!-- required due to floating-right action buttons --> */}
                                    <div className="clearfix"></div>
                                </fieldset>
                            </form>
                        </div>
                    );
                }
                }

            </Formik>
        );
    }

    submitButtonText() {
        if (this.props.submitButtonText) {
            return this.props.submitButtonText;
        } else {
            return 'Add';
        }
    }

    closeButtonText() {
        if (this.props.closeButtonText) {
            return this.props.closeButtonText;
        } else {
            return 'Cancel';
        }
    }
}
const mapStateToProps = _state => {
    return {
    };
}
const mapDispatchToProps = (dispatch) => {
    return {
        hasRole: (uiPart = '') => dispatch(actions.hasRole(uiPart))
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(NewOrderForm);
