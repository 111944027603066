import React, { Component } from 'react';
import AccountsReactTable from './Accounts/AccountsReactTable';
import NewAccountForm from './Accounts/NewAccountForm';
import AccountsSearch from './Accounts/AccountsSearch';
import { canAccess, isSystemOwnerSystemAcc } from './CallMSUIHelpers.js';

import { Link } from 'react-router-dom'
import ActionHeader from './ActionHeader.js';

import * as actions from './store/actions/index';
import { connect } from 'react-redux';

class Accounts extends Component {

    constructor(props) {
        super(props);
        this.state = {
            tableRevision: 1
        }
    }

    triggerTableUpdate() {
        this.setState(prevState => ({
            tableRevision: prevState.tableRevision + 1
        }));
    }

    render() {
        var searchAccount = <AccountsSearch />;
        var searchAccountDirect = null;
        var newAccountForm = null;

        if (this.props.hasRole('AccountAdd')) {
            newAccountForm = (
                <NewAccountForm accountUpdateTrigger={() => this.triggerTableUpdate()}>{searchAccount}</NewAccountForm>
            );
        } else {
            searchAccountDirect = <div>{searchAccount}<div className="clearfix"></div></div>;
        }

        var link = null;
        if (this.props.account && this.props.account.roles && canAccess('MyCustomerPortal', this.props.account.roles)) {
            var defaultPath = (isSystemOwnerSystemAcc(this.props.baseAccountInfo.Roles, this.props.account)) ? 'services/jobs' : 'users';
            link = (
                <Link 
                    key="custportal" 
                    to={"/portal/" + this.props.account.Id + '/' + defaultPath}>
                        <div style={{display:'inline-block', float:'right', position:'relative', top:'15px'}}>Customer Portal <i className="fa-solid fa-arrow-right"></i></div>
                </Link>
            );
        }


        return (
            <>
                <div className="Accounts">
                    <ActionHeader headerName={'Account Name'} headerText={this.props.account.Name} className="shortenedAccountName">{link}</ActionHeader>
                    {newAccountForm}
                    {searchAccountDirect}
                    <AccountsReactTable
                        lastUpdate={this.state.tableRevision}
                        accountUpdateTrigger={() => this.triggerTableUpdate()}
                    />
                </div>
            </>
        );
    }
}
const mapStateToProps = state => {
    const account = state.account;
    return {
        account: account.account,
        baseAccountInfo: account.baseAccountInfo
    };
}
const mapDispatchToProps = (dispatch) => {
    return {
        hasRole: (uiPart = '') => dispatch(actions.hasRole(uiPart))
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Accounts);