import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';

export function getBreadcrumbString(hier) {
    if (hier && hier.length > 0) {
        var out = [];
        hier.forEach(function (account, index) {
            out.push(account.Name);
        });
        return out.join(" / ");
    } else {
        return '';
    }
}

class SearchAccountBreadcrumbs extends Component {
    constructor(props) {
        super(props);
        this.changeAccount = this.changeAccount.bind(this);
    }

    changeAccount(e, accId) {
        e.preventDefault();

        /**
         * Don't set the base account, rather let the 
         * componentDidUpdate in BaseAccount handle this based on changes in URI props.
         */
        this.props.history.push('/accounts/'+accId);
    }

    makeLinkComponent(account) {
        var self = this;

        if (self.props.useLinks) {
            return <Link onClick={self.props.onClickCb} to={"/accounts/" + account.Id}>{account.Name}</Link>;
        } else {
            return <button className="btn btn-link" title= {account.Name} onClick={(e) => self.changeAccount(e, account.Id)}>{account.Name}</button>;
        }
    }

    render() {
        var self = this;
        if (this.props.accountHierarchy === undefined
            || this.props.accountHierarchy.length === 0
        ) {
            return null;
        }

        // Ensure we don't fiddle the original hierarchy
        var ah = this.props.accountHierarchy.slice(0);

        /**
         * Hierarchy is done from base account, so for customer portal we need
         * to add our end account in to the list
         */
        if (this.props.account !== undefined && this.props.account !== null
            && this.props.account.hasOwnProperty('Id')
        ) {
            var last = ah.slice(-1).pop();
            if (last !== undefined && last.Id !== this.props.account.Id) {
                ah.push(this.props.account);
            }
        }

        var ahList = [];
        var leftArrow = null;
        ah.forEach(function (account, index) {
            var ele = null;
            if (index === ah.length - 1) {
                ele = (<li key={account.Id} className="breadcrumb-item active" title={account.ExternalId}>
                    {self.props.linkLeaf ? self.makeLinkComponent(account) :  <button className="accountName btn btn-link" title={account.Name}>{account.Name}</button>
            }
                </li>);
            } else if (index === ah.length - 2) {
                ele = (<li key={account.Id} className="breadcrumb-item" title={account.ExternalId} >
                    {self.makeLinkComponent(account)}
                </li>);
                leftArrow = (
                    <button className="btn btn-default btn-default-tight" onClick={(e) => self.changeAccount(e, account.Id)}>
                        <i className="fa-solid fa-arrow-left"></i>
                    </button>
                );
            } else {
                ele = <li key={account.Id} className="breadcrumb-item" title={account.ExternalId} >
                    {self.makeLinkComponent(account)}
                    </li>
            }
            ahList.push(ele);
        });

        if (self.props.breadcrumbsOnly) {
            leftArrow = null;
        }

        return (
            <div className={"breadcrumbs-wrapper " + (self.props.className ? self.props.className : "")}>
                {leftArrow}
                <div className="breadcrumbs">
                    <ol className="breadcrumb">
                        {ahList}
                    </ol>
                </div>
            </div>
        );
    }
}
export default withRouter(SearchAccountBreadcrumbs);