import React, { Component } from 'react';
import { toast } from 'react-toastify';
import { Formik } from 'formik';
import { SubmitButton } from '../../FormHelpers';
import { cmsUploadCompanyLogo, cmsDeleteCompanyLogo, cmsDownloadCompanyLogo } from '../../CallMSAPI.js';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import { connect } from 'react-redux';
import * as actions from '../../store/actions/index';

const validFileTypes = ['.gif', '.jpg', '.jpeg', '.png', '.icon', '.svg', '.svg+xml'];

var _ = require('lodash');

const defaultCropSettings = {
    unit: 'px',
    width: 180,
    height: 54,
    x: 0,
    y: 0,
};

class BrandingLogoForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            sourceImage: null,
            croppedImage: null,
            type: '',
            size: 0,
            isCropEnabled: false,
            isDeleteEnabled: false,
            crop: defaultCropSettings,
        };

        this.uploadId = _.uniqueId('upload_');
    }

    componentDidMount() {
        this.downloadCompanyLogo();
    }

    downloadCompanyLogo = () => {
        var self = this;

        cmsDownloadCompanyLogo(
            self.props.account.Id,
            function (response) {
                var image = `data:${response.ContentType};base64,${response.Image}`;

                self.getImageDimensions(image).then(dimensions => {
                    self.setState({
                        sourceImage: image,
                        croppedImage: image,
                        type: response.ContentType,
                        size: response.Image.length,
                        crop: {
                            ...defaultCropSettings,
                            width: dimensions.width,
                            height: dimensions.height
                        },
                        isCropEnabled: !self.isSvgFileType(response.ContentType),
                        isDeleteEnabled: true
                    })
                })
            }
        )
    }

    getImageDimensions = (file) => {
        return new Promise(function (resolved) {
            var image = new Image()
            image.onload = function () {
                resolved({ width: image.width, height: image.height })
            };
            image.src = file
        })
    }

    isValidFileType = (type) => {
        return type && validFileTypes.includes(type.replace('image/', '.'));
    }

    isValidFileSize = (size) => {
        return size && !((size / Math.pow(1024, 2.0)) > 1);
    }

    isSvgFileType = (type) => {
        switch (type.replace('image/', '.')) {
            case '.svg':
            case '.svg+xml':
                return true;
            default:
                return false;
        }
    }

    onSelectFile = (e) => {
        var self = this;
        if (e.target.files
            && e.target.files.length > 0
            && this.isValidFileType(e.target.files[0].type)) {

            var file = e.target.files[0];
            const reader = new FileReader();

            reader.addEventListener('load', () => {
                self.getImageDimensions(reader.result).then(dimensions => {

                    if (this.isSvgFileType(file.type)) {
                        self.setState({
                            croppedImage: reader.result,
                            type: file.type,
                            size: file.size,
                            isCropEnabled: false
                        });
                    } else {
                        self.setState({
                            sourceImage: reader.result,
                            croppedImage: reader.result,
                            isCropEnabled: true,
                            type: file.type,
                            size: file.size,
                            crop: {
                                ...defaultCropSettings,
                                width: dimensions.width >= defaultCropSettings.width
                                    ? defaultCropSettings.width
                                    : dimensions.width,
                                height: dimensions.height >= defaultCropSettings.height
                                    ? defaultCropSettings.height
                                    : dimensions.height,
                            },
                        });
                    }
                })
            });

            reader.readAsDataURL(file);
        }

    };

    onDeleteCompanyLogo = () => {
        var self = this;

        cmsDeleteCompanyLogo(
            self.props.account.Id,
            function (ok) {
                toast.success("Image successfully deleted.");

                self.props.refreshAccount(() => {
                    self.setState({
                        sourceImage: null,
                        croppedImage: null,
                        type: '',
                        size: 0,
                        isDeleteEnabled: false
                    });
                    document.getElementById(self.uploadId).value = '';
                });

            }, function (error) {
                toast.error(error);
            }
        )
    };

    onImageLoaded = (image) => {
        this.imageRef = image;
    };

    onCropComplete = (crop) => {
        var self = this;
        if (self.imageRef && crop.width && crop.height) {
            var croppedImage = self.getCroppedImage(
                self.imageRef,
                crop,
                self.state.type)

            self.setState({
                croppedImage: croppedImage,
                size: croppedImage.length
            });
        }
    }

    getCroppedImage(image, crop, type) {
        const canvas = document.createElement('canvas');
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        canvas.width = crop.width;
        canvas.height = crop.height;
        const ctx = canvas.getContext('2d');

        ctx.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            crop.width,
            crop.height
        );

        return canvas.toDataURL(type);
    }

    onCropChange = (crop) => {
        this.setState({ crop });
    };

    render = () => {
        var self = this;

        return (
            <>
                <div className="row">
                    <div className="col-md-12">
                        <h2>Portal Logo</h2>
                    </div>
                </div>
                <Formik
                    enableReinitialize={true}
                    initialValues={self.state}
                    validate={values => {
                        let errors = {};

                        if (values.croppedImage === null) {
                            errors.image = 'You must select an image.';
                        } else if (!self.isValidFileType(values.type)) {
                            errors.image = `Please select a valid file type (${validFileTypes.join(' ')}).`;
                        } else if (!self.isValidFileSize(values.size)) {
                            errors.image = 'Invalid file size, maximum limit is 1MB.';
                        }

                        return errors;
                    }}

                    onSubmit={(originalValues, { setSubmitting }) => {
                        var values = _.cloneDeep(originalValues);
                        cmsUploadCompanyLogo(
                            self.props.account.Id,
                            { 'Image': values.croppedImage.replace(/^data:.+;base64,/, '') },
                            function (ok) {
                                setSubmitting(false);
                                toast.success("Image successfully uploaded.");

                                self.props.refreshAccount(() => {
                                    self.setState({
                                        isDeleteEnabled: true,
                                        sourceImage: originalValues.croppedImage,
                                        crop: {
                                            ...self.state.crop,
                                            x: 0,
                                            y: 0
                                        }
                                    });
                                });

                            }, function (error) {
                                setSubmitting(false);
                                toast.error(error);
                            }
                        );
                    }}>
                    {({
                        values,
                        errors,
                        handleSubmit,
                        isSubmitting
                    }) => {
                        return (
                            <>
                                <form role="form" className='form form-company-logo' onSubmit={handleSubmit}>
                                    <fieldset disabled={isSubmitting}>
                                        <div className='alert alert-info domain-form-wrapper'>
                                            <div className='row'>
                                                <div className={values.isCropEnabled ? 'col-md-6' : 'col-md-12'}>
                                                    {values.croppedImage
                                                        ? <>
                                                            <h4>Preview</h4>
                                                            <div className='logo-container'>
                                                                <img alt='Crop' className={`logo-img ${this.isSvgFileType(values.type) ? 'w-100': ''}`} src={values.croppedImage} />
                                                            </div>
                                                            <div className='row'>
                                                                <div className='col-md-3'>
                                                                    <h5>Height: {self.state.crop.height}px</h5>
                                                                </div>
                                                                <div className='col-md-3'>
                                                                    <h5>Width: {self.state.crop.width}px</h5>
                                                                </div>
                                                            </div>
                                                            <div className='row'>
                                                                <div className='col-md-12'>
                                                                    <p><em>For optimal display we recommend a maximum height of 54px.</em></p>
                                                                </div>
                                                            </div>
                                                        </>
                                                        : <p><em>You don't currently have a header logo configured. Use the button below to get started and upload one.</em></p>
                                                    }
                                                    <div className='row'>
                                                        <div className='col-md-12'>
                                                            <div className='form-group'>
                                                                <div className='row'>
                                                                    <div className='col-md-12'>
                                                                        <input type='file' id={self.uploadId} accept={`${validFileTypes.join(',')}`} onChange={self.onSelectFile} style={{ display: 'none' }} />
                                                                        <label style={{ width: '200px', backgroundColor: 'rgb(239, 239, 239)' }} className='btn mb-2' htmlFor={self.uploadId}>Choose Image To Upload</label>
                                                                    </div>
                                                                </div>
                                                                <div className='row'>
                                                                    <div className='col-md-12'>
                                                                        {errors.image &&
                                                                            <span className='error-message'>{errors.image}</span>}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                {values.isCropEnabled && (
                                                    <div className='col-md-6'>
                                                        <h4>Crop Image</h4>
                                                        <div className='logo-container'>
                                                            {values.sourceImage && (
                                                                <ReactCrop
                                                                    src={values.sourceImage}
                                                                    crop={self.state.crop}
                                                                    ruleOfThirds
                                                                    className='img-logo'
                                                                    onImageLoaded={self.onImageLoaded}
                                                                    onComplete={this.onCropComplete}
                                                                    onChange={this.onCropChange}
                                                                />
                                                            )}
                                                        </div>
                                                        <div className='row'>
                                                            <div className='col-md-12'>
                                                                <p><em>Drag the squares below to crop the image. Once saved this cannot be undone.</em></p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                            <div className='row'>
                                                <div className='col-sm-12'>
                                                    {values.croppedImage && (
                                                        <SubmitButton className={'btn btn-primary mb-2 right-action-button'} isSubmitting={isSubmitting}>Save</SubmitButton>
                                                    )}
                                                    {self.state.isDeleteEnabled &&
                                                        <button type='button' className='btn btn-default'
                                                            onClick={(e) => {
                                                                e.preventDefault();
                                                                self.onDeleteCompanyLogo();
                                                                return false;
                                                            }}><i className='fa-solid fa-trash'></i>
                                                        </button>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </fieldset>
                                </form>
                            </>
                        )
                    }
                    }
                </Formik>
            </>
        )
    }
}
const mapStateToProps = state => {
    const account = state.account;
    return {
        account: account.account
    };
}
const mapDispatchToProps = (dispatch) => {
    return {
        refreshAccount: (callBack = () => { }) => dispatch(actions.refreshAccount(callBack))
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(BrandingLogoForm);