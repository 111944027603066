import React, { Component } from 'react';

class ActionHeader extends Component {
    render() {
        return (
            <div className="action-header" title={this.props.headerName} role="heading">
                <div className="action-header-text" style={{overflow: this.props.className ? 'hidden' : ''}}>
                    {this.props.smallHeader
                        ? <h3 style={{margin: '15px 0'}} className={`${this.props.className ?? ''}`} title={this.props.headerText}>{this.props.headerText}</h3>
                        : <h1 className={`${this.props.className ?? ''}`} title={this.props.headerText}> {this.props.headerText}</h1>
                    }
                    {this.props.subtitle}
                </div>
                <div className="action-header-actions">
                    {this.props.children}
                </div>
            </div>
        );
    }
}

export default ActionHeader;
