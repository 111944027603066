import React, { Component } from 'react';
import { getErrMsg, cmsDeleteAdminInvitePromise, cmsGet, cmsGetPromise } from '../CallMSAPI.js';
import ExpandingTable from '../ExpandingReactTable';
import { toast } from 'react-toastify';
import { connect } from 'react-redux';
import { showConsoleLogs } from '../CallMSUIHelpers.js';
import dayjs from '../dayjs.js'

class AdminInviteReactTable extends Component { 
    constructor(props) {
        super(props);

        this.state = {
            // Used by table
            filters:[],
            searchText: []
        }

        this.fetchData = this.fetchData.bind(this);
        this.tableFilter = this.tableFilter.bind(this);
    }

    fetchData(filterValues, searchText, pageSize, page, sortSettings) {
        var self = this;

        var apiParams = {
            accountId: self.props.account.Id,
            objectType: 'admininvitations',
            relevantOnly: true,
            IncludeInactive: true
        };

        if (apiParams.accountId === undefined) {
            return;
        }

        if (pageSize) {
            apiParams['pageSize'] = pageSize;
        }

        if (page) {
            apiParams['currentPage'] = page;
        }

        if(sortSettings) {
            apiParams['SortBy'] = sortSettings.Column;
            apiParams['SortDirection'] = sortSettings.SortDirection;
        }

        if(searchText) {
            if (searchText.name ) {
                apiParams['searchText'] = searchText.name;
            }
            if (searchText.email ) {
                apiParams['searchText'] = searchText.email;
            }
        }

        return cmsGetPromise(apiParams)
    }

    tableFilter(filter, onChange, type) {
        var self = this;
        var opts = null;

        return (
            <input
                onChange={event => {
                    let temp =[];
                    temp[type] = event.currentTarget.value;
                    self.setState({searchText:  temp});
                }}
                style={{ width: "100%" }}
                value={self.state.searchText[type]}
            />
        );
    }

    deleteUserInvite(event, data) {
        var self = this;
        event.preventDefault();
        if (window.confirm("Are you sure you want to delete this invitation?")) {
            cmsDeleteAdminInvitePromise(
                self.props.account.Id,
                data.Id
            ).then(function () {
                toast.success("Invitation deleted");
            }, function (err) {
                toast.error("Unable to delete invitation : " + getErrMsg(err));
            }).finally(function () {
                self.props.userUpdateTrigger();
            })
        }
    }

    render() {
        var self = this;
        
        var cols = [
            {
                id: "name",
                Header: "Name",
                accessor: function (d) {
                    if (!d.FirstName && !d.LastName) {
                        return <em>Not Set</em>;
                    }
                    return (d.FirstName ? d.FirstName : '') + ' ' + (d.LastName ? d.LastName : '');
                },
                sortable: true,
                Filter: ({ filter, onChange }) => self.tableFilter(filter, onChange, "name")
            },
            {
                id: "email",
                Header: "Email",
                accessor: d => d.Email,
                sortable: true,
                Filter: ({ filter, onChange }) => self.tableFilter(filter, onChange, "email")
            },
            {
                id: "sentdate",
                Header: "Sent Date",
                accessor: function (d) {
                    let t = dayjs.utc(d.SentDate);
                    if (t.isValid()) {
                        return t.fromNow();
                    }
                },
                filterable: false,
                sortable: true
            },
            {
                id: "status",
                Header: "Status",
                accessor: function (d) {
                    if (d.Status === 'Active') {
                        return 'Not Accepted';
                    } else {
                        return d.Status;
                    }
                },
                filterable: false,
                sortable: false
            },
            {
                id: "delete",
                Header: "",
                className: "text-right",
                width: 50,
                accessor: function (d) {
                    return (
                        <button onClick={(e) => self.deleteUserInvite(e, d)} className="btn btn-xs btn-default">
                            <i className="fa-solid fa-trash"></i>
                        </button>
                    );
                },
                filterable: false
            }
        ];

        return (
            <ExpandingTable
                fetchData={self.fetchData}
                columns={cols}
                update={self.props.lastUpdate}
                sortBy={{
                    Column: 'sentdate',
                    SortDirection: 'Descending',
                }}
                filters={self.state.filters}
                searchText={self.state.searchText}
                loadingText={"Loading Data..."}
                noDataText={"You don't have any pending admin invitations."}
                showPagination={true}
                pageSizeOptions={[10, 20, 50]}
                className="-striped -highlight"
            />
        );
    }
}
const mapStateToProps = state => {
    const account = state.account;
    return {
        account: account.account
    };
}
const mapDispatchToProps = (_dispatch) => {
    return {

    }
}
export default connect(mapStateToProps, mapDispatchToProps)(AdminInviteReactTable);
