import React, { Component } from 'react';
import ExpandingTable from '../ExpandingReactTable';
import { cmsGetServicesHistoryPromise } from '../CallMSAPI';
import { getUserName } from '../MSALAuthProvider';
import { toast } from 'react-toastify';
import { connect } from 'react-redux';
import ServiceAuditFormWrapper from './ServiceAuditFormWrapper';
import { showConsoleLogs } from '../CallMSUIHelpers';
import dayjs from '../dayjs.js';

class ServiceAudit extends Component {
    constructor(props) {
        super(props);
        this.state = {
            UPN: null
        };
    }
    componentDidMount() {
        let self = this;
        let username = getUserName();
        self.setState({ UPN: username });
    }
    render() {
        var self = this;
        if (self.state.UPN) {
            return (
                <>
                    <AuditHistoryReactTable account={self.props.account} UPN={self.state.UPN} />
                </>
            );
        } else {
            return null
        }
        
    }
}

class AuditHistoryReactTable extends Component {
    constructor(props) {
        super(props);

        this.state = {
            filters:[],
            searchText:[]
        };
        this.fetchData = this.fetchData.bind(this);
    }

    fetchData(filterValues, searchText, pageSize, page, sortSettings){
        var self = this;

        var apiParams = {
            actionId: null,
            fromDate: null,
            toDate: null,
        };

        if (pageSize) {
            apiParams['pageSize'] = pageSize;
        }

        if (page) {
            apiParams['currentPage'] = page;
        }

        if(sortSettings) {
            apiParams['SortBy'] = sortSettings.Column;
            apiParams['SortDirection'] = sortSettings.SortDirection;
        }

        return cmsGetServicesHistoryPromise(self.props.account.account.Id, apiParams);
    }

    render() {
        var self = this;

        return (
            <div>
                <h4>Service Updates</h4>
                <ExpandingTable
                    columns={[
                        {
                            id: "name",
                            Header: "Service Name",
                            accessor: function (d) {
                                let name = "";
                                if (d.AfterData) {
                                    name = JSON.parse(d.AfterData).Name;
                                } else if (d.BeforeData) {
                                    name = JSON.parse(d.BeforeData).Name;
                                }
                                return name;
                            },
                            sortable: false,
                            filterable: false,
                        },
                        {
                            id: "modifiedBy",
                            Header: "Modified By",
                            accessor: function (d) {
                                //return d.records[d.records.length - 1].ModifiedBy;
                                return d.UserPrincipalName;
                            },
                            sortable: false,
                            filterable: false,
                        },
                        {
                            id: "auditType",
                            Header: "Action",
                            accessor: function (d) {
                                //let record = d.records[d.records.length - 1]
                                return d.AuditAction.Name;
                            },
                            sortable: false,
                            filterable: false,
                        },
                        {
                            id: "modifiedon",
                            Header: "Modified On",
                            accessor: function (d) {

                                let t = dayjs.utc(d.Timestamp);
                                if (t.isValid()) {
                                    if (t.year() === dayjs().year()) {
                                        return <span title={t.fromNow()}>{t.local().format('MMM D, H:mm:ss')}</span>;
                                    } else {
                                        return <span title={t.fromNow()}>{t.local().format('MMM D YYYY, H:mm:ss')}</span>;
                                    }
                                }
                            },
                            sortable: false,
                            filterable: false
                        }
                    ]
                    }
                    sortBy={{
                        Column: 'name',
                        SortDirection: 'Descending',
                    }}
                    fetchData={self.fetchData}
                    loadingText={"Loading Data..."}
                    noDataText={"You don't have any Service History."}
                    showPagination={true}
                    pageSizeOptions={[10, 20, 50]}
                    className="-striped -highlight"
                    SubComponent={row => {
                        let service = row.original
                        
                        return (
                            <ServiceAuditFormWrapper prevService={service.BeforeData ? JSON.parse(service.BeforeData) : null} service={service.AfterData ? JSON.parse(service.AfterData) : null} AuditAction={service.AuditAction.Name} />
                        );
                    }}
                />
            </div>
        );
    }
}


const mapStateToProps = state => {
    const account = state.account;
    return {
        account: account,
        baseAccount: account.baseAccount
    };
}
const mapDispatchToProps = (_dispatch) => {
    return {

    }
}
export default connect(mapStateToProps, mapDispatchToProps)(ServiceAudit);