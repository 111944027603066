import React, { Component } from 'react';
import { Field, Formik } from 'formik';
import { getErrMsg, cmsGetAdminInvitationRoles, cmsUpdateAPIRolesPromise } from '../CallMSAPI.js';
import { toast } from 'react-toastify';
import Select from 'react-select';
import { SubmitButton } from '../FormHelpers';
import { connect } from 'react-redux';
var _ = require('lodash');


class EditAPIRolesForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            apiRolesLoaded: false,
            apiRoles: []
        };

        this.getFormDefaults = this.getFormDefaults.bind(this);
    }

    componentDidMount() {
        var self = this;

        cmsGetAdminInvitationRoles(
            self.props.account.Id,
            function (callmsData) {
                self.setState({
                    apiRoles: callmsData.Results,
                    apiRolesLoaded: true
                });
            },
            function (error) {
                toast.error("Roles error: " + error);
            }
        );
    }

    getFormDefaults() {
        var self = this;

        var baseDefaults = {
            Name: this.props.apiKey.Name,
            RoleIds: []
        };

        if (this.state.apiRolesLoaded) {
            if (this.props.apiKey && this.props.apiKey.RoleIds) {
                baseDefaults["RoleIds"] = this.state.apiRoles.filter(function (r) {
                    return self.props.apiKey.RoleIds.indexOf(r.Id) !== -1
                });
            }
        }

        return baseDefaults;
    }

    clear = (resetForm) => {
        resetForm();
        this.props.closeForm();
    }

    render() {
        var self = this;

        if (!this.state.apiRolesLoaded) {
            return (
                <div className="admin-inviteForm">
                    Loading resources...
                </div>
            );
        }

        return (
            <Formik
                // Required so updates from getFormDefaults get propagated down to form
                enableReinitialize={true}
                initialValues={this.getFormDefaults()}
                validate={values => {
                    let errors = {};
                    // Ensure at least one role is set
                    return errors;
                }}
                onSubmit={(originalValues, { setSubmitting, setErrors, resetForm }) => {
                    // var formik = this;

                    var values = _.cloneDeep(originalValues);

                    values["RoleIds"] = [].slice
                        .call(values["RoleIds"])
                        .map(option => option.Id);

                    cmsUpdateAPIRolesPromise(
                        self.props.account.Id,
                        self.props.apiKey.Id,
                        values
                    ).then(function () {

                        // Keep the form open for now after saves
                        toast.success("API key attributes successfully updated");

                        self.props.apiUpdateTrigger && self.props.apiUpdateTrigger();

                        self.props.closeForm();
                    }, function (err) {
                        toast.error("Unable to update API key: " + getErrMsg(err));
                    }).finally(function () {
                        setSubmitting(false);
                    })
                }}
            >

                {({
                    values,
                    handleSubmit,
                    isSubmitting,
                    setFieldValue,
                    resetForm
                    /* and other goodies */
                }) => {


                    // Make list of roles
                    var roles = null;
                    if (this.state.apiRolesLoaded === true) {
                        roles = (
                            <Select
                                name="RoleIds"
                                options={this.state.apiRoles}
                                className="select-dropdown"
                                // You need to set the new field value
                                onChange={function (opt) {
                                    setFieldValue("RoleIds", opt);
                                }}
                                value={values["RoleIds"]}
                                isMulti
                                getOptionLabel={option => option.Name}
                                getOptionValue={option => option.Id}
                            />
                        );
                    }

                    return (
                        <div className="inner-table-wrapper">
                            <form role="form" className="form update-admin-invite-form form-inline" onSubmit={handleSubmit}>
                                <fieldset disabled={isSubmitting}>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <label>Name</label> <Field name="Name" className="form-control" />
                                        </div>

                                        <div className="col-md-12">
                                            <label>Roles</label>
                                            {roles}
                                        </div>


                                        <div className="col-md-12">
                                            <div className="admin-user-form-action-buttons">
                                                <SubmitButton type='submit' className={"btn btn-primary pull-right"} isSubmitting={isSubmitting}> Update Roles </SubmitButton>
                                                <button type='button' className="btn btn-default" onClick={() => this.clear(resetForm)}>Cancel</button>
                                            </div>
                                        </div>
                                    </div>
                                </fieldset>
                            </form>
                        </div>
                    )
                }
                }

            </Formik>
        );
    }
}
const mapStateToProps = state => {
    const account = state.account;
    return {
        account: account.account,
    };
}
const mapDispatchToProps = (_dispatch) => {
    return {
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(EditAPIRolesForm);
