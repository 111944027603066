import React, { PureComponent } from 'react';
import { cmsAddServiceUserPartsPromise, getErrMsg } from '../CallMSAPI.js';
import { toast } from 'react-toastify';
import CSVReader from "react-csv-reader";
import { CSVLink} from "react-csv";
import { DefaultButton } from 'office-ui-fabric-react';
import { Dialog, DialogType, DialogFooter } from 'office-ui-fabric-react/lib/Dialog';
import { Field, Formik, ErrorMessage } from 'formik';
import { TeachingBubble } from 'office-ui-fabric-react/lib/TeachingBubble';
import { SubmitButton } from '../FormHelpers';
import { connect } from 'react-redux';

var _ = require('lodash');

class BulkUserImportForm extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            csvOutput: null,
            showCSVHelp: false,
            showUploadTypeHelp: false
        };

        this.csvFileLoaded = this.csvFileLoaded.bind(this);
        this.hideFormWrapper = this.hideFormWrapper.bind(this);
        this.endImport = this.endImport.bind(this);
        this.dismissCSVHelp = this.dismissCSVHelp.bind(this);
        this.dismissUploadTypeHelp = this.dismissUploadTypeHelp.bind(this);
        this.removeEmptyFields = this.removeEmptyFields.bind(this);
    }

    dismissCSVHelp() {
        this.setState({ showCSVHelp: false });
    }

    toggleCSVHelp() {
        this.setState(prevState => ({ showCSVHelp: !prevState.showCSVHelp }));
    }

    dismissUploadTypeHelp() {
        this.setState({ showUploadTypeHelp: false });
    }

    toggleUploadTypeHelp() {
        this.setState(prevState => ({ showUploadTypeHelp: !prevState.showUploadTypeHelp }));
    }

    hideFormWrapper(e) {
        e && e.preventDefault();
        this.props.hideForm && this.props.hideForm();
    }

    getFormDefaults() {
        var services = _.filter(this.props.services, function (s) { return (s.ServiceVariantCode === 'PBX' || s.ServiceVariantCode === 'TRUNK') });
        return {
            csvInput: null,
            uploadOption: '',
            currentServiceId: (services && services.length === 1 ? services[0].Id : '')
        }
    }

    endImport(e, resetForm) {
        var self = this;
        e.preventDefault();

        resetForm && resetForm();
        self.setState({
            csvOutput: null
        }, function () {
            // Important, we can't clear the CSV selector form element otherwise
            self.hideFormWrapper(e);
        });
    }

    removeEmptyFields(data) {
        data.csvInput.forEach((entry) => {
            Object.keys(entry).forEach(key => {
                if (entry[key] === '' || entry[key] == null) {
                    delete entry[key];
                }
            });
        })
    }

    csvFileLoaded(setFieldValue, data, fileInfo) {

        if (data) {
            if (data.length > 10000) {
                toast.error(data.length + " users exceeds the 10000 user CSV limit. Please split the file and try again.");
                return;
            }

            _.forEach(data, function (d) {
                if (d['TrunkNumber']) {
                    d['Identifier'] = d['TrunkNumber'];
                    delete d['TrunkNumber'];
                }
                if (d['SIPUsername']) {
                    d['Identifier'] = d['SIPUsername'];
                    delete d['SIPUsername'];
                }
            });
            setFieldValue('csvInput', data);
        } else {
            toast.error("Unable to prepare CSV file");
        }
    };

    handleCsvError(err) {
        toast.error("Unable to load CSV: " + getErrMsg(err));
    }

    importCsv(values, setSubmitting) {
        var self = this;

        // Strip out all values with empty strings
        self.removeEmptyFields(values);

        // Wipe out the previous results file first
        self.setState({
            csvOutput: null,
        },
            function () {
                cmsAddServiceUserPartsPromise(
                    self.props.account.Id,
                    values.currentServiceId,
                    values.uploadOption,
                    values.csvInput
                ).then(function (data) {
                    self.setState({
                        csvOutput: data,
                    }, function () {
                        setSubmitting && setSubmitting(false);
                    });

                }, function (err) {
                    toast.error("Error: " + getErrMsg(err));
                    self.setState({
                        csvOutput: null,
                    }, function () {
                        setSubmitting && setSubmitting(false);
                    });
                });
            }
        );
    }

    generateTemplate(values) {
        var s = _.find(this.props.services, function (o) { return values.currentServiceId && o.Id === values.currentServiceId });
        if (s && s.ServiceVariantCode !== 'PBX') {
            // Trunk doesn't have username/password columns
            return [
                [
                    "TrunkNumber", // Identifier
                    "Name",
                    "Email",
                    "ExternalId",
                    "CallerId",
                    "DisplayName"
                ]
            ];
        } else {
            return [
                [
                    "SIPUsername", // Identifier
                    "Name",
                    "Email",
                    "PhoneNumber",
                    "AuthUsername",
                    "Password",
                    "ExternalId"
                ]
            ];
        }
    }

    render() {
        var self = this;

        const papaparseOptions = {
            header: true,
            dynamicTyping: false,
            skipEmptyLines: true,
            transformHeader: header =>
                header
                    .replace(/\W/g, '')
        };

        if (!this.props.isVisible) {
            return null;
        }

        return (
            <Formik
                // Required so updates from getFormDefaults get propagated down to form
                enableReinitialize={true}
                initialValues={this.getFormDefaults()}
                validate={values => {
                    let errors = {};

                    // Ensure PBX username is set
                    if (!values.currentServiceId) {
                        errors.currentServiceId = 'You must select a service.';
                    }

                    if (!values.uploadOption) {
                        errors.uploadOption = 'You must select the type of upload.';
                    }

                    if (!values.csvInput) {
                        errors.csvInput = 'You must select a file to upload.';
                    }

                    return errors;
                }}
                onSubmit={(originalValues, { setSubmitting, setErrors, resetForm }) => {


                    // We make various mods to the data before submission, we
                    // don't want to fiddle the underlying data though.
                    var values = _.cloneDeep(originalValues);
                    // debugger;

                    self.importCsv(values, setSubmitting);
                    // self.submitCallback(formik, values, setSubmitting, setErrors, resetForm);
                }}
            >

                {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                    setFieldValue,
                    setSubmitting,
                    setErrors,
                    resetForm
                    /* and other goodies */
                }) => {

                    var optionList = [
                        <option key={'emptyChoice'} value='' disabled={true}>Select A Service</option>
                    ];
                    _.forEach(this.props.services, function (s) {
                        if (s && s.Id && (s.ServiceVariantCode === 'PBX' || s.ServiceVariantCode === 'TRUNK')) {
                            optionList.push(
                                <option key={s.Id} value={s.Id}>{s.Name}</option>
                            );
                        }
                    });

                    var uploadOptionList = [];
                    var isPBX = false;
                    if (values.currentServiceId) {
                        var s = _.find(this.props.services, function (o) { return values.currentServiceId && o.Id === values.currentServiceId });
                        if (s && s.ServiceVariantCode === 'PBX') {
                            isPBX = true;
                            uploadOptionList.push(<option value="Create">Create</option>);
                            uploadOptionList.push(<option value="ModifyMatchingExternalId">Create or Update by External Id</option>)
                            uploadOptionList.push(<option value="ModifyMatchingIdentifier">Create or Update by SIP Username</option>);
                            if (values && values.uploadOption === '') {
                                setFieldValue('uploadOption', 'Create');
                            }
                        } else {
                            isPBX = false;
                            uploadOptionList.push(<option value="ModifyMatchingIdentifier">Update by Trunk Number</option>);
                            if (values && values.uploadOption === '') {
                                setFieldValue('uploadOption', 'ModifyMatchingIdentifier');
                            }
                        }
                    } else {
                        uploadOptionList.push(<option value="">-</option>);
                        if (values && values.uploadOption !== '') {
                            setFieldValue('uploadOption', '');
                        }
                    }

                    var inProgressModal = null;
                    if (isSubmitting || self.state.csvOutput !== null) {

                        var ok = 0;
                        var error = 0;

                        // Example:
                        // Email: "foo@email"
                        // ErrorMessage: "Phone number must begin with '+' (E164)"
                        // ExternalId: "sipid_external_id"
                        // Identifier: "sipid"
                        // Name: "name"
                        // PhoneNumber: "12345"
                        // Success: "Failed"

                        if (self.state.csvOutput) {
                            _.forEach(self.state.csvOutput, function (d) {
                                if (d && d.Success && d.Success !== "Failed") {
                                    ok++;
                                } else {
                                    error++;
                                }
                            });
                        }

                        var csvOutput = null;
                        if (this.state.csvOutput !== null) {
                            csvOutput = (<><br /><CSVLink data={this.state.csvOutput} filename={"import-results.csv"}><i className="fa-solid fa-download"></i> CSV Results</CSVLink></>);
                        }

                        inProgressModal = (
                            <Dialog
                                hidden={false} // !this.props.isOpen}
                                onDismiss={(e) => this.endImport(e, resetForm)}
                                isBlocking={true}
                                dialogContentProps={{
                                    type: DialogType.normal,
                                    title: csvOutput ? 'Import Complete' : 'Importing Users',
                                    showCloseButton: false,
                                }}
                                modalProps={{
                                    styles: { main: { maxWidth: 450 } },
                                }}
                            >
                                {csvOutput ? <p>{ok} Users successfully processed, {error} failed. Download the results file for further information.</p> : null}
                                {csvOutput
                                    ? csvOutput
                                    : <p className="text-center"><i class="fa-solid fa-spinner fa-pulse"></i> Import In Progress</p>
                                }

                                <DialogFooter>
                                    <DefaultButton onClick={(e) => this.endImport(e, resetForm)} text={"Close"} disabled={isSubmitting} />
                                </DialogFooter>
                            </Dialog>
                        );
                    }

                    return (
                        <form role="form" className="form user-form update-domain-form" onSubmit={handleSubmit}>
                            <fieldset disabled={isSubmitting}>
                            <div className="alert alert-info domain-form-wrapper domainForm">
                                <div className="row">
                                <div className="col-md-3">
                                        <label className="w-100">
                                            <span style={{padding: '7px 0', display: 'inline-block'}}>Service:</span>
                                            <Field className="form-control" component="select" name={"currentServiceId"}>
                                                {optionList}
                                            </Field>
                                        </label>
                                        <ErrorMessage name={"currentServiceId"} />
                                    </div>
                                    <div className="col-md-3">
                                        <label className="w-100"> <span style={{padding:isPBX ? '6px 0' : '7px 0', display: 'inline-block'}}>Import Type:</span>
                                            {isPBX ?
                                                <button className="btn btn-link btn--faux-link" onClick={(e) => { e.preventDefault(); self.toggleUploadTypeHelp(); return false; }}>
                                                    <i className="fa-solid fa-question-circle" id={"uploadTypeHelp"}></i>
                                                </button>
                                                : null}
                                            <Field className="form-control" component="select" name={"uploadOption"}>
                                                {uploadOptionList}
                                            </Field>
                                            {self.state.showUploadTypeHelp ?
                                            <TeachingBubble
                                                target={'#uploadTypeHelp'}
                                                hasCondensedHeadline={true}
                                                onDismiss={() => self.dismissUploadTypeHelp()}
                                                hasCloseIcon={true}
                                                closeButtonAriaLabel="Close"
                                                headline={"Upload Type Help"}
                                            >
                                                <ul>
                                                    <li><strong>Create: </strong>This will only add new PBX/Trunk entries if they don't already exist.</li>
                                                    <li><strong>Update: </strong>This will add new entries if they don't exist, it will also update existing entries based on the new values in the CSV upload. You can Update by providing the SIP Username or External Identifier of the current configured users. </li>
                                                </ul>
                                            </TeachingBubble>
                                            : null}
                                        </label>
                                        <ErrorMessage name={"uploadOption"} />
                                    </div>
                                    <div className="col-md-3">
                                        <label className="w-100"> <span style={{padding: '7px 0', display: 'inline-block'}}>CSV File:</span>
                                            <CSVReader
                                                cssClass="csv-reader-input input-group"
                                                onFileLoaded={(d, e) => this.csvFileLoaded(setFieldValue, d, e)}
                                                onError={this.handleCsvError}
                                                parserOptions={papaparseOptions}
                                                // TODO: Style the button to not be 90s
                                                inputStyle={{ color: 'grey' }}
                                            />
                                        </label>
                                        <ErrorMessage name={"csvInput"} />
                                    </div>
                                    <div className="col-md-3">
                                        <div> <label>&nbsp;</label> </div>
                                        <CSVLink
                                            data={this.generateTemplate(values)}
                                            filename={"import-template.csv"}
                                            onClick={() => {
                                                if (!values.currentServiceId) {
                                                    toast.info("Please select a service to activate the CSV template download");
                                                    return false;
                                                }
                                                else {
                                                    return true;
                                                }
                                            }}
                                        ><i className="fa-solid fa-download"></i> Download CSV Template</CSVLink>

                                        <button className="btn btn-link btn--faux-link" onClick={(e) => { e.preventDefault(); self.toggleCSVHelp(); return false; }}>
                                            <i className="fa-solid fa-question-circle" id={"downloadCSV"}></i>
                                        </button>

                                        {self.state.showCSVHelp ?
                                            <TeachingBubble
                                                target={'#downloadCSV'}
                                                hasCondensedHeadline={true}
                                                onDismiss={() => self.dismissCSVHelp()}
                                                hasCloseIcon={true}
                                                closeButtonAriaLabel="Close"
                                                headline={"CSV Template Columns"}
                                            >
                                                {isPBX ? 
                                                        <ul>
                                                            <li><strong>SIPUsername: </strong> The SIP username/identifier for the specific user.</li>
                                                            <li><strong>Name: </strong> User's Full Name.</li>
                                                            <li><strong>Email: </strong> User's email address. For auto matching of upload with Team's users this field must match their O365 email address.</li>
                                                            <li><strong>PhoneNumber: </strong> The user's phone number on the PBX. This must be in E164 format.</li>
                                                            <li><strong>AuthUsername: </strong> The SIP Auth name used on the PBX device. If not set the SIP username is used instead.</li>
                                                            <li><strong>Password: </strong> The SIP password used on the PBX device</li>
                                                            <li><strong>ExternalId: </strong> External ID, 'You can use this as an optional unique identifier controlled, e.g. a PBX user ID that references users in your own system.</li>
                                                        </ul>
                                                    : 
                                                        <ul>
                                                            <li><strong>TrunkNumber: </strong> The user's phone number. This must match a number in the configured trunk number range. This must also be in E164 format.</li>
                                                            <li><strong>Name: </strong> User's Full Name.</li>
                                                            <li><strong>Email: </strong> User's email address. For auto matching of upload with Team's users this field must match their O365 email address.</li>
                                                            <li><strong>ExternalId: </strong> External ID, 'You can use this as an optional unique identifier controlled, e.g. a PBX user ID that references users in your own system.</li>
                                                        </ul>
                                                    }
                                            </TeachingBubble>
                                            : null}
                                    </div>

                                    <div className="col-md-12">
                                        <hr />
                                        <SubmitButton className={"btn btn-primary mb-2 right-action-button"} isSubmitting={isSubmitting}> Upload </SubmitButton>
                                        <button disabled={isSubmitting} className="btn right-action-button mb-2 close-form" onClick={this.hideFormWrapper}>Cancel</button>
                                    </div>
                                </div>


                                {inProgressModal}
                            </div>
                            </fieldset>
                        </form>
                    );
                }}

            </Formik>
        );
    }
}
const mapStateToProps = state => {
    const services = state.services;
    const account = state.account;
    return {
        account: account.account,
        services: services.rawList
    };
}
const mapDispatchToProps = (_dispatch) => {
    return {}
}
export default connect(mapStateToProps, mapDispatchToProps)(BulkUserImportForm);