import React, { Component } from 'react';
import { toast } from 'react-toastify';
import { cmsGetAllAdminUsersHistoryPromise } from '../CallMSAPI';
import AdminUserRoleHistoryTable from './AdminUserRoleHistoryTable';
import ReactTable from "react-table";
import ExpandingTable from '../ExpandingReactTable';
import { connect } from 'react-redux';
import dayjs from '../dayjs.js';


var _ = require('lodash');

class Audit extends Component {
    constructor(props) {
        super(props);

        this.state = {
            // Used by table
            filters: [],
            searchText: []
        }

        this.fetchData = this.fetchData.bind(this);
    }

    fetchData(filterValues, searchText, pageSize, page, sortSettings) {
        var self = this;

        var apiParams = {
            accountId: self.props.account.Id,
            objectType: 'adminusers/history'
        };

        if (pageSize) {
            apiParams['pageSize'] = pageSize;
        }

        if (page) {
            apiParams['currentPage'] = page;
        }

        if(sortSettings) {
            apiParams['SortBy'] = sortSettings.Column;
            if(sortSettings.Column === 'name'){
                apiParams['SortBy'] = 'FirstName';
            }
            if(sortSettings.Column === 'Altered On'){
                apiParams['SortBy'] = 'AlteredOn';
            }
            apiParams['SortDirection'] = sortSettings.SortDirection;
        }

        return cmsGetAllAdminUsersHistoryPromise(apiParams);
    }

    render() {
        var self = this;

        return (
            <>
                <h4> Admin Users</h4>
                <br />
                <div>
                    <ExpandingTable
                        columns={[
                            {
                                id: "name",
                                Header: "Name",
                                accessor: function (d) {
                                    return d.FirstName + ' ' + d.LastName;
                                },
                                colWidth: 200,
                                filterable: false
                            },
                            {
                                id: "email",
                                Header: "Email",
                                accessor: function (d) {
                                    return d.Email;
                                },
                                colWidth: 200,
                                filterable: false
                            },
                            {
                                id: "status",
                                Header: "Status",
                                className: "text-center",
                                accessor: function (d) {
                                    return d.Status;
                                },
                                sortable: false,
                                filterable: false,
                            },
                            {
                                id: "alteredon",
                                Header: "Altered On",
                                className: "text-center",
                                accessor: function (d) {
                                    let t = dayjs.utc(d.AlteredOn);
                                    if (t.isValid()) {
                                        if (t.year() === dayjs().year()) {
                                            return <span title={t.fromNow()}>{t.local().format('MMM D, H:mm:ss')}</span>;
                                        } else {
                                            return <span title={t.fromNow()}>{t.local().format('MMM D YYYY, H:mm:ss')}</span>;
                                        }
                                    } else {
                                        return d.ModifiedOn;
                                    }
                                },
                                filterable: false,
                                colWidth: 150
                            },
                            {
                                id: "modifiedby",
                                Header: "Modified By",
                                className: "text-center",
                                accessor: function (d) {
                                    return d.ModifiedBy;
                                },
                                sortable: false,
                                filterable: false,
                            },
                        ]
                        }
                        fetchData={self.fetchData}
                        filters={self.state.filters}
                        searchText={self.state.searchText}
                        loadingText={"Loading Data..."}
                        noDataText={"You don't have any Admin User Change History."}
                        showPagination={true}
                        pageSizeOptions={[5, 10, 20]}
                        className="-striped -highlight"
                        SubComponent={(row, closeRow) => {
                            var userAdminId = null;
                            if (row.original.Id) {
                                userAdminId = row.original.Id;
                            }

                            if (userAdminId) {
                                return (<AdminUserRoleHistoryTable adminUserId={userAdminId} />);
                            } else {
                                return ('No records to show');
                            }

                        }}
                    />
                </div>
            </>
        );
    }
}
const mapStateToProps = state => {
    const account = state.account;
    return {
        account: account.account,
        baseAccount: account.baseAccount
    };
}
const mapDispatchToProps = (_dispatch) => {
    return {

    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Audit);
