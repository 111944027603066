import React, { Component } from 'react';
import { Formik } from 'formik';
import { SubmitButton } from '../../FormHelpers';
import FieldWithDisposition from '../../Services/FieldWithDisposition';
import { cmsEditExternalLink, cmsAddExternalLink, cmsDeleteExternalLink } from '../../CallMSAPI.js';
import { getPostExternalLink, getNewExternalLinkData } from './BrandingExternalLinksAssembler.js';
import { toast } from 'react-toastify';
import { connect } from 'react-redux';

var _ = require('lodash');

var urlRegex = "(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})";

class BrandingExternalLinkForm extends Component {
    constructor(props) {
        super(props); 
    }

    getTitle = (typeName) => {
        return `${typeName.replace(/^\w/, c => c.toUpperCase())} External Link`;
    }

    deleteExternalLink = (resetForm, values, accountId) => {
        var self = this;
        cmsDeleteExternalLink(
            accountId,
            values.Id,
            function (ok) {
                toast.success(`${self.getTitle(values.ExternalLinkTypeName)} successfully deleted.`);

                 resetForm({
                     values: getNewExternalLinkData(
                         values.ExternalLinkTypeId,
                         values.ExternalLinkTypeName)
                })
            }, function (error) {
                toast.error(`Unable to delete ${self.getTitle(values.ExternalLinkTypeName)}: ${error}`);
            }
        )
    };

    render = () => {
        var self = this;
        return (
            <>
                <Formik
                    enableReinitialize={true}
                    initialValues={self.props.initialValues}
                    validate={values => {
                        let errors = {};

                        if (values.RequiresUrl) {
                            if (!values.URL) {
                                errors.URL = 'You must enter a URL.';
                            } else if (values.ExternalLinkTypeName === 'support' && values.URL.startsWith('freshworks')) {
                                // this is fine, support has a special case on freshworks
                            } else {
                                var regex = new RegExp(urlRegex);

                                if (!values.URL.match(regex)) {
                                    errors.URL = 'You must enter a valid URL.';
                                }
                            }
                        }

                        if (!values.Text) {
                            errors.Text = 'You must enter an Internal Display Name.';
                        }

                      return errors;
                    }}

                    onSubmit={(originalValues, { setSubmitting }) => {
                        var values = _.cloneDeep(originalValues);

                        if (values.Id) {
                            cmsEditExternalLink(
                                self.props.account.Id,
                                values.Id,
                                getPostExternalLink(values),
                                function (ok) {
                                    setSubmitting(false);
                                    toast.success(`${self.getTitle(values.ExternalLinkTypeName)} successfully saved.`);
                                }, function (error) {
                                    setSubmitting(false);
                                    toast.error(`Unable to update ${self.getTitle(values.ExternalLinkTypeName)}: ${error}`);
                                }
                            )
                        } else {
                            cmsAddExternalLink(
                                self.props.account.Id,
                                getPostExternalLink(values),
                                function (response) {
                                    originalValues.Id = response.Id;
                                    setSubmitting(false);
                                    toast.success(`${self.getTitle(values.ExternalLinkTypeName)} successfully saved.`);
                                }, function (error) {
                                    setSubmitting(false);
                                    toast.error(`Unable to add ${self.getTitle(values.ExternalLinkTypeName)}: ${error}`);
                                }
                            )
                        }
                    }}>
                    {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        isSubmitting,
                        setFieldValue,
                        resetForm,
                    }) => {
                        return (
                            <>
                                <form role="form" className="form form-company-external-links" onSubmit={handleSubmit}>
                                    <fieldset disabled={isSubmitting}>
                                        <div className={`alert ${self.props.initialValues.IsSystemOwnerOnly ? 'system-owner-container' : 'alert-info'} domain-form-wrapper`}>
                                            {self.props.initialValues.IsSystemOwnerOnly &&
                                                <div style={{ textAlign: "center", paddingBottom: "10px" }}>
                                                    <i className="fa-solid fa-user-secret" title="System Owner Only" style={{ float: "left" }}></i>
                                                    <small>System Owner Only</small>
                                                </div>
                                            }
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <h4>{self.getTitle(self.props.initialValues.ExternalLinkTypeName)}</h4>
                                                    { self.props.initialValues.ExternalLinkTypeName
                                                      && self.props.initialValues.ExternalLinkTypeName === 'support'
                                                      ? <p>The Support link is shown in the top right page header via the <i className="fa-solid fa-question-circle"></i> icon.</p>
                                                        :null }
                                                    { self.props.initialValues.ExternalLinkTypeName
                                                      && self.props.initialValues.ExternalLinkTypeName === 'store'
                                                      ? <p>The Store link is shown in the top right page header via the <i className="fa-solid fa-cart-shopping"></i> icon.</p>
                                                        :null }
                                                </div>
                                            </div>
                                            <div className="row">
                                                {self.props.initialValues.RequiresUrl &&
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <FieldWithDisposition
                                                                visibility={'EditMandatory'}
                                                                formObject={{
                                                                    Type: 'text',
                                                                    Id: 'URL',
                                                                    Label: 'URL',
                                                                }}
                                                                formValues={values}
                                                                touched={touched}
                                                                errors={errors}
                                                                setFieldValue={setFieldValue}
                                                            />
                                                        </div>
                                                    </div>
                                                }
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <FieldWithDisposition
                                                            visibility={'EditMandatory'}
                                                            formObject={{
                                                                Type: 'text',
                                                                Id: 'Text',
                                                                Label: 'Internal Display Name'
                                                            }}
                                                            formValues={values}
                                                            touched={touched}
                                                            errors={errors}
                                                            setFieldValue={setFieldValue}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-4">
                                                    <div className="form-group">
                                                        <FieldWithDisposition
                                                            visibility={'EditOptional'}
                                                            formObject={{
                                                                Type: 'checkbox',
                                                                Id: 'VisibleToAccount',
                                                                Label: 'Visible To Account',
                                                                CheckedLabel: 'Enabled',
                                                                CheckedValue: 'true'
                                                            }}
                                                            formValues={values}
                                                            touched={touched}
                                                            errors={errors}
                                                            setFieldValue={setFieldValue}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-md-4">
                                                    <div className="form-group">
                                                        <FieldWithDisposition
                                                            visibility={'EditOptional'}
                                                            formObject={{
                                                                Type: 'checkbox',
                                                                Id: 'VisibleToChildren',
                                                                Label: 'Visible To Children',
                                                                CheckedLabel: 'Enabled',
                                                                CheckedValue: 'true'
                                                            }}
                                                            formValues={values}
                                                            touched={touched}
                                                            errors={errors}
                                                            setFieldValue={setFieldValue}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-md-4">
                                                    <div className="form-group">
                                                        <FieldWithDisposition
                                                            visibility={'EditOptional'}
                                                            formObject={{
                                                                Type: 'checkbox',
                                                                Id: 'VisibleToGrandDescendents',
                                                                Label: 'Visible To Grand Descendents',
                                                                CheckedLabel: 'Enabled',
                                                                CheckedValue: 'true'
                                                            }}
                                                            formValues={values}
                                                            touched={touched}
                                                            errors={errors}
                                                            setFieldValue={setFieldValue}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-12">
                                                    <div className="user-form-action-buttons">
                                                        <SubmitButton className={"btn btn-primary mb-2 right-action-button"} isSubmitting={isSubmitting}>Save</SubmitButton>
                                                        {values.Id !== '' &&
                                                            <button type="button" className="btn btn-default"
                                                                onClick={(e) => {
                                                                    e.preventDefault(); 
                                                                    self.deleteExternalLink(
                                                                        resetForm,
                                                                        values,
                                                                        self.props.account.Id
                                                                    );
                                                                    return false;
                                                                }}><i className="fa-solid fa-trash"></i>
                                                            </button>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </fieldset>
                                </form>
                            </>
                        )}
                    }
                </Formik>
            </>
        )
    }
}
const mapStateToProps = state => {
    const account = state.account;
    return {
        account: account.account
    };
}
const mapDispatchToProps = (_dispatch) => {
    return {
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(BrandingExternalLinkForm);


